var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qAndA", class: { open: _vm.answerOpen } }, [
    _c("div", { on: { click: _vm.toggleAnswer } }, [
      _c("div", {
        domProps: {
          innerHTML: _vm._s("<h4 class='h5 question'>" + _vm.question + "</h4>")
        }
      })
    ]),
    _c("div", { ref: "answer", staticClass: "answer" }, [
      _c("div", {
        staticClass: "answer-content",
        domProps: { innerHTML: _vm._s("<span>" + _vm.answer + "</span>") }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }