var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "form-input-wrapper" }, [
      _c("label", { staticClass: "label-text" }, [
        _vm._v(_vm._s(_vm.formLabel))
      ]),
      _c("div", { staticClass: "input-wrapper" }, [
        _c("div", { staticClass: "input-inner" }, [
          _c("input", {
            ref: "inputValue",
            class: _vm.$v.$dirty && _vm.$v.$anyError ? "" : "",
            attrs: { type: "text" },
            domProps: { value: _vm.formValue },
            on: {
              input: _vm.updateFormValue,
              focus: function($event) {
                _vm.inFocus = true
              },
              blur: function($event) {
                _vm.inFocus = false
              }
            }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.inFocus,
                  expression: "!inFocus"
                }
              ],
              staticClass: "input-cover",
              class: _vm.formattedValueStyle,
              on: { click: _vm.hideFormatting }
            },
            [_vm._v(" " + _vm._s(_vm.formatValue) + " ")]
          )
        ]),
        _vm.dirtyInput
          ? _c("div", { staticClass: "error-messages" }, [
              !_vm.$v.formValue.required && _vm.$v.$dirty
                ? _c("small", [_vm._v("Field must not be empty")])
                : _vm._e(),
              !_vm.$v.formValue.isDate &&
              _vm.$v.$dirty &&
              _vm.$v.formValue.$model !== ""
                ? _c("small", [_vm._v("Value must be a valid date")])
                : _vm._e(),
              _vm.$v.formValue.isDate &&
              !_vm.$v.formValue.isBetween &&
              _vm.$v.$dirty &&
              _vm.$v.formValue.$model !== ""
                ? _c("small", [_vm._v("Please check your date input")])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]),
    _vm.helpText
      ? _c("div", { staticClass: "extra-info open" }, [
          _vm._v(" " + _vm._s(_vm.helpText) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }