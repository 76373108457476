var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { staticClass: "loading" }, [_c("loading")], 1)
    : _c("div", { staticClass: "password-access" }, [
        _vm._m(0),
        _c(
          "button",
          {
            staticClass: "btn btn--standard btn--equal",
            on: { click: _vm.login }
          },
          [_vm._v(" Access ")]
        )
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "password-access-wording" }, [
      _c("p", [_vm._v("Login with your account")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }