var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-pannel-wrapper" },
    [
      _vm.showVideo
        ? _c("base-modal", {
            key: "video",
            attrs: { modalOverlay: "video" },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        [
                          _c("Video", {
                            attrs: { videoToShow: _vm.videoToShow },
                            on: { closeModal: _vm.closeVideo }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1783734748
            )
          })
        : _vm._e(),
      _vm.isPopUp
        ? _c("base-modal", {
            key: "popup",
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "benefits-wrapper" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "close-popup",
                              on: { click: _vm.closeModal }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "times"] }
                              })
                            ],
                            1
                          ),
                          _c("PopUpTextContent")
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              628611362
            )
          })
        : _vm._e(),
      _c("div", { staticClass: "row-1" }, [
        _c("div", { staticClass: "introduction" }, [
          _c("p", [
            _vm._v(
              " This illustration tool has been designed to help you understand the proposed changes to pensions for teachers. You might find it useful to watch the video guide to using the previous version of this illustration tool on the legacy hub before getting started. "
            )
          ]),
          _vm._m(0),
          _c("p", [
            _vm._v(
              " If you have any issues with My Pension Online or if you think the information on your TPS benefit statement is incorrect, please click "
            ),
            _c(
              "span",
              { staticClass: "benefits-popup", on: { click: _vm.showPopUp } },
              [_vm._v("here")]
            ),
            _vm._v(". ")
          ]),
          _c("p", [
            _vm._v(
              " Please note that we can’t answer any questions in relation to what you should do. Details of how to find further advice and assistance can be found in the consultation guide. "
            )
          ]),
          _c("p", { staticClass: "highlight" }, [
            _vm._v(
              " Please note, your inputs and personal data are not stored when using this tool. "
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "row-2" },
        [
          _c("RadioInput", {
            attrs: {
              inputName: "Gender",
              radioOptions: ["Male", "Female"],
              dataValue: _vm.data.general.gender,
              dirtyInput: _vm.dirtyInput,
              dataKey: "gender",
              tooltipData:
                "To accurately work out your benefits, and in particular calculate your state pension age, we need to know your gender."
            },
            on: {
              "update-parent-value": _vm.updateValue,
              "update-parent-error": _vm.updateError
            }
          }),
          _c("FormInputDate", {
            attrs: {
              dataKey: "dateOfBirth",
              formLabel: "Date of birth (dd/mm/yyyy)",
              dirtyInput: _vm.dirtyInput,
              dataValue: _vm.data.general.dateOfBirth,
              minDate: _vm.minAge,
              maxDate: _vm.maxAge
            },
            on: {
              "update-parent-value": _vm.updateValue,
              "update-parent-error": _vm.updateError
            }
          }),
          _c("RadioInput", {
            attrs: {
              inputName: "Do you work part time?",
              radioOptions: ["No", "Yes"],
              dataValue: _vm.data.general.isPartTime,
              dirtyInput: _vm.dirtyInput,
              dataKey: "isPartTime",
              tooltipData:
                "You should select 'No' if you work full-time.  If you work less than full-time, please select 'Yes'."
            },
            on: {
              "update-parent-value": _vm.updateValue,
              "update-parent-error": _vm.updateError
            }
          }),
          _vm.data.general.isPartTime === "Yes"
            ? _c("FormInput", {
                attrs: {
                  inputType: "percentage",
                  dataKey: "currentPartTimeProportion",
                  formLabel:
                    "As a percentage, what proportion of your full time hours do you work?",
                  minValue: 1,
                  maxValue: 100,
                  dirtyInput: _vm.dirtyInput,
                  helpText:
                    "As an example, if you worked 3 out of 5 days you should enter a vaule of 60 (to represent 60%)",
                  dataValue: _vm.data.general.currentPartTimeProportion
                },
                on: {
                  "update-parent-value": _vm.updateValue,
                  "update-parent-error": _vm.updateError
                }
              })
            : _vm._e(),
          _c("FormInput", {
            attrs: {
              inputType: "pounds",
              dataKey: "currentFullTimeSalary",
              formLabel:
                _vm.data.general.isPartTime === "Yes"
                  ? "Your full time equivalent gross annual salary, including pensionable allowances"
                  : "Your gross annual salary, including pensionable allowances",
              minValue: 1,
              maxValue: 1000000,
              dirtyInput: _vm.dirtyInput,
              helpText:
                _vm.data.general.isPartTime === "Yes"
                  ? "This should be the salary you would receive if you worked full time"
                  : "",
              dataValue: _vm.data.general.currentFullTimeSalary
            },
            on: {
              "update-parent-value": _vm.updateValue,
              "update-parent-error": _vm.updateError
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " You will need to enter some information before we can illustrate the proposed changes. In particular, you will need to refer to your TPS benefit statement for the information on the next page. You can access it through TPS My Pension Online "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.teacherspensions.co.uk/public/login.aspx",
            target: "_blank"
          }
        },
        [_vm._v("here.")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }