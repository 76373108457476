












import { Component, Vue } from 'vue-property-decorator';
@Component
export default class QuestionFeedbackWrapper extends Vue {
  closeModal() {
    this.$emit('showFeedbackModal');
  }
}
