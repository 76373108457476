











































import { Component, Vue, Prop } from 'vue-property-decorator';
import PrettyRadio from 'pretty-checkbox-vue/radio';

@Component({
  components: { PrettyRadio },
})
export default class RadioInput extends Vue {
  @Prop() readonly inputName!: string;
  @Prop() readonly dataValue!: number;
  @Prop() readonly dataKey!: string;
  @Prop() readonly tooltipData!: string;
  @Prop() readonly helpText!: string;
  @Prop() readonly dirtyInput!: boolean;
  @Prop({ type: Array, required: true })
  radioOptions!: string[];

  checkedValue: any = 'low';

  isActive = false;
  isInFocus = false;
  isError = true;

  created() {
    this.checkedValue = this.dataValue;
    if (this.checkedValue !== null) this.isError = false;
  }

  updateRadioValue(e) {
    // check here
    this.checkedValue = e;
    // this.$v.formValue.$touch();
    this.$emit('update-parent-value', this.dataKey, this.checkedValue);
    this.isError = false;
    this.$emit('update-parent-error', this.dataKey, this.isError);
  }
}
