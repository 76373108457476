


























import { Vue, Component } from 'vue-property-decorator';
import TermsAndConditionsWording from '@/components/UI/TermsAndConditionsWording.vue';
import { showTerms, showCookie } from '../observables/ui';
import dayjs from 'dayjs';

@Component({
  components: {
    TermsAndConditionsWording,
  },
})
export default class FooterBar extends Vue {
  getYear = dayjs().format('YYYY');
  openTerms() {
    showTerms();
  }

  openCookieBar() {
    showCookie();
  }
}
