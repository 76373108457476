



















import { Component, Vue, Prop } from 'vue-property-decorator';
import DateDropdown from 'vue-date-dropdown';
import moment from 'moment';

@Component({
  components: { DateDropdown },
})
export default class DatePickerInput extends Vue {
  @Prop() readonly datePickerName!: string;
  @Prop() readonly dataKey!: string;
  @Prop() readonly dataValue!: any;
  @Prop() readonly min!: any;
  @Prop() readonly max!: any;
  @Prop() readonly validate!: boolean;

  selectedDate = '';

  get dateIsvalid() {
    return this.dateIsValidFn(this.selectedDate);
  }

  dateIsValidFn(date: string) {
    const isValid = moment(date, 'DD.MM.YYYY').isValid();
    // this.$emit('update-parent-value', 'isErrors', false);
    if (isValid)
      this.$emit(
        'update-parent-value',
        this.dataKey,
        moment(date, 'DD.MM.YYYY').format('DD/MM/YYYY'),
      );
    return isValid;
  }

  created() {
    this.selectedDate = moment(this.dataValue, 'DD/MM/YYYY').format('DD.MM.YYYY');
  }
}
