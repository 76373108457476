var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cookie-bar", class: { "show-cookie": _vm.showCookieBar } },
    [
      _c("div", { staticClass: "header" }, [
        _vm._v(
          " This website stores data such as cookies to enable essential site functionality, as well as to analyse usage and performance. Please make a choice on your preferences. "
        )
      ]),
      _c(
        "div",
        { staticClass: "content" },
        _vm._l(_vm.radioOptions, function(option, key) {
          return _c(
            "PrettyRadio",
            {
              key: key,
              staticClass: "p-default p-fill",
              attrs: { name: "tracking-option", color: "black", value: option },
              on: { change: _vm.updateRadioValue },
              model: {
                value: _vm.checkedOption,
                callback: function($$v) {
                  _vm.checkedOption = $$v
                },
                expression: "checkedOption"
              }
            },
            [_vm._v(" " + _vm._s(option) + " ")]
          )
        }),
        1
      ),
      _c("div", { staticClass: "btn-holder" }, [
        _c(
          "button",
          { staticClass: "btn btn--inverse", on: { click: _vm.hideCookieBar } },
          [_vm._v("Close")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }