var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-input-wrapper" },
    [
      _c("label", { staticClass: "label-text", attrs: { for: "" } }, [
        _vm._v(_vm._s(_vm.formLabel))
      ]),
      _c("input", {
        attrs: { type: "text", id: _vm.formLabel, name: "" },
        on: {
          focus: function($event) {
            _vm.isInFocus = true
          },
          blur: function($event) {
            _vm.isInFocus = false
          }
        }
      }),
      _c("vSlider", {
        staticClass: "my-slider",
        attrs: { tooltip: "none", dragOnClick: "" },
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      }),
      _c(
        "div",
        {
          class:
            "extra-info " + (_vm.isActive || _vm.isInFocus ? "open" : "close")
        },
        [
          _vm._v(
            " Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem, aspernatur saepe. Sint voluptatum mollitia repellendus blanditiis, saepe nihil nemo voluptatem? "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }