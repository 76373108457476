var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", [
        _vm._v(
          " The Girls’ Day School Trust Pensions Review Website Terms and Conditions of Use "
        )
      ]),
      _c("div", { staticClass: "access--content" }, [
        _c("h3", [_vm._v("Using the Website")]),
        _c("p", [
          _vm._v(
            " The Girls’ Day School Trust Pensions Review Website, includes: "
          )
        ]),
        _c("ul", [
          _c("li", [_vm._v("the GDST Pensions Review modeller; and")]),
          _c("li", [
            _vm._v('the educational microsite; (together, the "Website").')
          ])
        ]),
        _c("p", [
          _vm._v(
            " The Website has been developed, and is maintained, by Lane Clark & Peacock LLP on behalf of The Girls’ Day School Trust (company number 6400) (the “GDST”). By using the Website, you confirm that you accept these terms and conditions (the “Terms”) and that you agree to comply with them. If you do not agree to these Terms, you must not use the Website. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Any part of these Terms may change without notice at any time and your use of the Website will be deemed as acceptance of these Terms as most recently changed. You are advised to regularly check the Terms of the Website. Any part of the Website may be modified without warning or liability arising from such modification. These Terms were last amended on 29 July 2021. "
          )
        ]),
        _c("h3", [_vm._v("Access and Use")]),
        _c("p", [
          _vm._v(
            " The Website is intended for the sole use of UK-based teachers at schools owned and operated by the GDST and who are members of the Teachers’ Pension Scheme (the “TPS”). "
          )
        ]),
        _c("p", [_vm._v("Access to the Website is only intended for:")]),
        _c("ul", [
          _c("li", [
            _vm._v(
              " UK-based teachers at schools owned and operated by the GDST who are members of the TPS, and to whom the GDST has made, or will make, single sign-on functionality available, using existing system log-in credentials; and "
            )
          ]),
          _c("li", [
            _vm._v(
              " other named users who have been specifically given access by the GDST (whether via single sign-on or by some other means). "
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            " If you have been given access by mistake or suspect that an unauthorised person has gained access using your credentials, you must inform us promptly at consultation@wes.gdst.net. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Neither the GDST, nor any school within its group, makes any representation that materials on the Website are appropriate or available for use in any location other than the UK. If you are accessing the Website from any location other than the UK, you do so on your own initiative and are responsible for compliance with local laws, if, and to the extent that, local laws are applicable. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Any dissemination, forwarding or copying of any part of the Website is strictly prohibited and may be unlawful. "
          )
        ]),
        _c("h3", [_vm._v("Liability and Disclaimer")]),
        _c("p", [
          _vm._v(
            " The Website is intended to be used for illustrative purposes only, and its results do not constitute a promise or guarantee of the benefits you will receive. The availability of and the information on the Website does not constitute financial advice or other professional advice, nor a recommendation of a particular course of action. The Website is not intended to illustrate anything that falls under FCA jurisdiction. "
          )
        ]),
        _c("p", [
          _vm._v(
            " The Website may be used as a starting point for considering your options in respect of the TPS, but you should not rely on the Website when making your decisions, as a number of simplifications and assumptions have been made. Always carry out your own research as to what is right for your specific circumstances. This is especially important if your circumstances are complex or non-standard. We also recommend you take financial advice before making any decisions. You can find a financial advisor near you by visiting the Money Advice Service. "
          )
        ]),
        _c("p", [
          _vm._v(
            " The Website is provided with no warranties expressed or otherwise implied relating to the accuracy, fitness for purpose, compatibility or security of any components of the Website. There is no guarantee that the Website, or any content on it, will be free from errors, omissions, bugs or viruses. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Where the Website contains links to other websites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources, and do not endorse nor accept responsibility for them. "
          )
        ]),
        _c("p", [
          _vm._v(
            " None of the GDST, any of the schools that it owns and operates, Lane Clark & Peacock LLP, nor any of their respective members, officers or employees, accepts any responsibility or liability for any loss, damage or inconvenience caused by action taken (or a decision not to take action) as a result of information provided by the Website. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Lane Clark & Peacock LLP does not accept any responsibility or liability whatsoever to users of the Website. "
          )
        ]),
        _c("p", [
          _vm._v(
            " Nothing in these Terms excludes or limits the liability of any person for fraud or for death or personal injury arising from its negligence. "
          )
        ]),
        _c("h3", [_vm._v("Data Protection")]),
        _c("p", [_vm._v("No personal data entered in the Website is stored.")]),
        _c("h3", [_vm._v("Other Matters")]),
        _c("p", [
          _vm._v(
            " If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck out and the remaining provisions shall remain in force. "
          )
        ]),
        _c("p", [
          _vm._v(
            " These Terms shall be governed by and construed in accordance with English law, and we and you each submit to the exclusive jurisdiction of the English Courts. "
          )
        ]),
        _c("h3", [_vm._v("Cookies")]),
        _c("p", [
          _vm._v("We use the following types of cookies on this website:")
        ]),
        _c("ul", [
          _c("li", [
            _c("strong", [_vm._v("Strictly necessary cookies.")]),
            _vm._v(
              " These are cookies that are required for the operation of the Website. "
            )
          ]),
          _c("li", [
            _c("strong", [_vm._v("Analytical/performance cookies.")]),
            _vm._v(
              " These are cookies that allow us to recognise and count the number of visitors and to see how visitors move around the Website when they are using it. This helps us to improve the way the Website works, for example, by ensuring that users are finding what they are looking for easily. "
            )
          ])
        ]),
        _c("div", [
          _vm._v(
            " The table below gives more information about the individual analytical/performance cookies we use and the purposes for which we use them. "
          ),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "td-small" }, [_vm._v("Cookie")]),
                _c("th", [_vm._v("Purpose")]),
                _c("th", { staticClass: "td-small" }, [_vm._v("More info")])
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "td-small" }, [
                  _vm._v("Google Analytics (_ga)")
                ]),
                _c("td", { staticClass: "td-large" }, [
                  _vm._v(
                    " Like many services, Google Analytics uses first-party cookies to track visitor interactions. These cookies are used to store information, such as what time the current visit occurred, whether the visitor has been to the site before, and what site referred the visitor to the web page. Browsers do not share first-party cookies across domains. "
                  )
                ]),
                _c("td", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://policies.google.com/technologies/cookies?hl=en",
                        target: "_blank"
                      }
                    },
                    [_vm._v("More info")]
                  )
                ])
              ])
            ])
          ])
        ]),
        _c("p", [
          _vm._v(
            " Your browser will allow you to restrict, block or remove any cookies set by the Website. Details of this functionality will vary by browser but can usually be found in the browser help section. The website "
          ),
          _c(
            "a",
            {
              attrs: { href: "https://www.aboutcookies.org", target: "_blank" }
            },
            [_vm._v("www.aboutcookies.org")]
          ),
          _vm._v(
            " contains more details about cookies and options for dealing with them. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }