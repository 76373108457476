var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "information--container" }, [
    _c("div", [
      _c("div", { staticClass: "layout" }, [
        _c("h2", { staticClass: "section--heading" }, [
          _vm._v("Important documents")
        ]),
        _c(
          "div",
          { staticClass: "information--content" },
          _vm._l(_vm.documents, function(document, index) {
            return _c("DocumentBox", {
              key: index,
              attrs: { document: document }
            })
          }),
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }