
































































































import { Component, Vue } from 'vue-property-decorator';

import FeedbackIcon from '@/components/UI/FeedbackIcon.vue';
// legacy function
// import { questionOrFeedback } from '@/observables/ui';

@Component({
  components: {
    FeedbackIcon,
  },
})
export default class NavBar extends Vue {
  showSideBar = false;
  showTerms = false;

  get getCurrentPage(): string {
    return this.$route.name;
  }

  get getViewHubVersion(): string {
    return this.getCurrentPage === 'LandingPage' ? 'Current' : 'Legacy';
  }

  goToLandingPage(): void {
    if (this.getCurrentPage === 'LandingPage') {
      this.$router.push('/');
    } else {
      this.$router.push('/legacy');
    }
  }

  handleSideBar() {
    this.showSideBar = !this.showSideBar;
  }

  closeSideBar() {
    this.showSideBar = false;
  }

  // legacy function
  // showGetIntouch() {
  //   questionOrFeedback.showFeedbackOrQuestion = true;
  //   this.$gtag.screenview({
  //     app_name: 'hub',
  //     screen_name: 'get in touch',
  //   });
  // }
}
