var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "impact" }, [
      _c("div", { staticClass: "header" }, [
        _vm._v(" Impact on your salary from October 2023 ")
      ]),
      _vm.illustration.retireInConsultation
        ? _c("div", { staticClass: "illustration-text" }, [
            _vm._v(
              " Based on your chosen illustration you are due to retire before the implementation date. "
            )
          ])
        : _c("div", { staticClass: "illustration-text" }, [
            _vm._v(
              " Based on your illustration choices, the overall impact of you choosing Option 2 would be an approximate "
            ),
            _c("span", { staticClass: "highlight" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.illustration.inServiceImpact.approxTakehomePay >
                      _vm.illustration.inServiceImpact.approxTakehomePayTPS
                      ? "increase"
                      : "decrease"
                  ) +
                  " in your take home pay of £" +
                  _vm._s(
                    _vm._f("numeral")(
                      Math.round(
                        Math.abs(
                          _vm.illustration.inServiceImpact.approxTakehomePay -
                            _vm.illustration.inServiceImpact
                              .approxTakehomePayTPS
                        ) / 10
                      ) * 10,
                      "0,0"
                    )
                  ) +
                  " per month from October 2023 compared with Option 1."
              )
            ])
          ])
    ]),
    _c("div", { staticClass: "atretirement" }, [
      _c("div", { staticClass: "header" }, [_vm._v(" Retirement lump sum ")]),
      _c("div", { staticClass: "illustration-text" }, [
        _vm._v(
          " Based on your illustration choices, under Option 2 you would receive a retirement lump sum (at age " +
            _vm._s(_vm.options.retirementAge) +
            ") of approximately £" +
            _vm._s(
              _vm._f("numeral")(
                Math.round(_vm.illustration.pensions.illustratedLumpSum / 100) *
                  100,
                "0,0"
              )
            ) +
            ". This compares with a retirement lump sum of approximately £" +
            _vm._s(
              _vm._f("numeral")(
                Math.round(
                  _vm.illustration.pensions.illustratedLumpSumTPS / 100
                ) * 100,
                "0,0"
              )
            ) +
            " under Option 1. "
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "charting" },
      [
        _c("div", { staticClass: "header" }, [
          _vm._v(" Retirement cashflow illustration ")
        ]),
        _c("chart", {
          key: "cashflowchart",
          attrs: { options: _vm.retirementIncomeChart }
        }),
        _vm.illustration.pensions.remainingDCPot > 0 &&
        _vm.options.illustrateDC == "Drawdown"
          ? _c("div", { staticClass: "remainingpot" }, [
              _vm._v(
                " Your illustration choices mean that, under Option 2, you could have funds remaining in your Flexible Pension Plan of approximately £" +
                  _vm._s(
                    _vm._f("numeral")(
                      Math.round(
                        _vm.illustration.pensions.remainingDCPot / 100
                      ) * 100,
                      "0,0"
                    )
                  ) +
                  " at age 100. "
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "subtext" }, [
          _vm._v(
            "For both the GDST FPP and the TPS, your actual outcome could be higher or lower than illustrated. See the Assumptions tab for more information."
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "impact" }, [
      _c("div", { staticClass: "header" }, [_vm._v(" What are the options? ")]),
      _c("div", { staticClass: "illustration-text" }, [
        _c("p", [
          _vm._v(
            " The Illustration Tool shows the potential difference in your salary, retirement lump sum, and annual pension income when comparing "
          )
        ]),
        _c("ul", [
          _c("li", [
            _c("b", [_vm._v("Option 1:")]),
            _vm._v(
              " Staying in the TPS - the dashed line in the cashflow illustration; and "
            )
          ]),
          _c("li", [
            _c("b", [_vm._v("Option 2:")]),
            _vm._v(
              " Moving to the GDST Flexible Pension Plan from October 2023 - the bars in the cashflow illustration. "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }