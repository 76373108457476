var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("section", { staticClass: "footer--body" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "layout" }, [
          _c("div", { staticClass: "footer--content" }, [
            _c("div", { staticClass: "footer--content-col" }, [
              _c("div", { staticClass: "link--items-content" }, [
                _c("div", { staticClass: "company" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/svg/logo-dark-bg.svg") }
                  }),
                  _c("p", [_vm._v("10 Bressenden Place, London, SW1E 5DH")]),
                  _c("p", [_vm._v("Telephone: +44 (0)20 7393 6666")])
                ])
              ])
            ]),
            _c("div", { staticClass: "footer--content-bottom" }, [
              _c("div", [
                _vm._v("© Copyright " + _vm._s(_vm.getYear) + " GDST")
              ]),
              _c(
                "div",
                {
                  staticClass: "terms-conditions",
                  on: { click: _vm.openTerms }
                },
                [_vm._v("Terms & Condtions")]
              ),
              _c(
                "div",
                {
                  staticClass: "terms-conditions",
                  on: { click: _vm.openCookieBar }
                },
                [_vm._v("Cookie Preferences")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }