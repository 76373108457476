var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "modeller--section-container" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "layout" }, [
        _c("h2", { staticClass: "section--heading" }, [
          _vm._v("Illustration tool")
        ]),
        _c("div", { staticClass: "no-modeller" }, [
          _vm._v(
            " To use the illustration tool please view on an iPad or a Laptop/Desktop device "
          )
        ]),
        _vm.isPageLegacy
          ? _c("div", { staticClass: "placeholder" }, [
              _c(
                "p",
                [
                  _vm._v(
                    " The modeller on this page is no longer supported, you can find the latest version on the "
                  ),
                  _c(
                    "router-link",
                    { staticClass: "text-underline", attrs: { to: "/" } },
                    [_vm._v("home")]
                  ),
                  _vm._v(" page ")
                ],
                1
              )
            ])
          : _c(
              "div",
              { staticClass: "placeholder" },
              [
                _c("p", [
                  _vm._v(
                    "We are currently updating the modeller, please check back soon."
                  )
                ]),
                _c("font-awesome-icon", {
                  attrs: { icon: ["fad", "chart-network"] }
                })
              ],
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }