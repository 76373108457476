


















































































import { Component, Vue } from 'vue-property-decorator';
import Video from '@/components/UI/Video.vue';
import { VueAgile } from 'vue-agile';

@Component({
  components: {
    agile: VueAgile,
    Video,
  },
})
export default class VideoGuides extends Vue {
  public videoToShow = '';
  proposeChanges = [
    {
      title: 'Introduction to pensions',
      contentTitle: 'Understanding what pensions are and how they work',
      content:
        'We know this isn’t your day job, so we have created this video to explain the basic concepts and commonly used terminology.',
      video:
        'https://gdststprod.blob.core.windows.net/videos/Introduction-to-pensions-v1-21-September.mp4?sp=r&st=2021-12-31T10:07:43Z&se=2022-12-31T18:07:43Z&spr=https&sv=2020-08-04&sr=b&sig=GMpi4qZlY98vIrJTIO3HciPkcYo4gxK1o6btOghHxQE%3D',
      thumbnail: '/img/Introduction-image.PNG',
      tab: 'introductiontopensions',
    },
    {
      title: 'The TPS',
      contentTitle: `Explaining how teachers’ pensions are currently provided at the GDST`,
      content: `All GDST teachers are currently in or eligible to join the Teachers’ Pension Scheme (TPS).  This video explains what it is and what benefits it provides.`,
      video:
        'https://gdststprod.blob.core.windows.net/videos/the-TPS-v1-22-september.mp4?sp=r&st=2021-12-31T10:09:01Z&se=2022-12-31T18:09:01Z&spr=https&sv=2020-08-04&sr=b&sig=rX4Vot%2FABFbIux0Jy3n2VTksa%2BbGMpM4LTvcP%2FaWB3E%3D',
      thumbnail: '/img/the-tps-v2.png',
      tab: 'thetps',
    },
    {
      title: 'The proposed changes',
      contentTitle: 'Explaining the changes to pensions for teachers the GDST is proposing',
      content:
        'We’re proposing changes to how you build up pension benefits going forwards.  This video explains the proposed changes in details and explores the impact it would have on any benefits you’ve already build-up in the TPS.',
      video:
        'https://gdststprod.blob.core.windows.net/videos/the-proposed-changes-v1-22-september.mp4?sp=r&st=2021-12-31T10:08:39Z&se=2022-12-31T18:08:39Z&spr=https&sv=2020-08-04&sr=b&sig=zmGZTuy0Ao%2BD3fmRg%2FJrYgG1iTSQK0qnkD8%2B7%2BqLVf8%3D',
      thumbnail: '/img/the-proposed-changesv2.PNG',
      tab: 'theproposedchanges',
    },
    {
      title: 'Pay and pension options',
      contentTitle: 'Understanding the key features of the proposed alternative pension scheme',
      content:
        'The proposed pay and pension options, including membership of the GDST Flexible Pension Plan, would give you flexibility over how you are rewarded.  This video explores this flexibility, and how you could shape both your current reward and retirement income to suit you if the proposals go ahead.',
      video:
        'https://gdststprod.blob.core.windows.net/videos/pay-and-pension-options-v1-22-september.mp4?sp=r&st=2021-12-31T10:08:11Z&se=2022-12-31T18:08:11Z&spr=https&sv=2020-08-04&sr=b&sig=fGbsLRhWOTaIhJjpqYUNjtnjuaayCeuMblJVxR6hg2I%3D',
      thumbnail: '/img/pay-and-pention-thumbnail.PNG',
      tab: 'payandpensionoptions',
    },
    {
      title: 'Impact on you',
      contentTitle: 'Exploring what the proposed changes could mean for you',
      content:
        'So what do the proposed changes mean in terms of your income now and in retirement?  This video explains how to use the illustration tool below to understand what the proposed changes could mean for you.',
      video:
        'https://gdststprod.blob.core.windows.net/videos/impact-on-you-20-september.mp4?sp=r&st=2021-12-31T10:07:06Z&se=2022-12-31T18:07:06Z&spr=https&sv=2020-08-04&sr=b&sig=yv%2FxC72iP4uOxJdICzbSiR9oM8T95M3HX1iltjD6rzo%3D',
      thumbnail: '/img/impact-thumbnail.PNG',
      tab: 'impactonyou',
    },
  ];

  sliderIndex = 0;
  showVideo = false;

  updateCarousel(index: number) {
    this.sliderIndex = index;
    const carousel = this.$refs.carousel as any;
    (this.$refs.understandChanges as HTMLElement).scrollIntoView();

    carousel.goTo(index);
  }
  updateSliderIndex(data: any) {
    this.sliderIndex = data.currentSlide;
  }
  handleScrollToSection() {
    this.$emit('scroll-to-section', 'Test');
  }

  handleShowVideo(index, title) {
    this.showVideo = true;
    this.videoToShow = this.proposeChanges[index].video;
    const pageV = `video - ${title}`;
    // eslint-disable-next-line
    this.$gtag.screenview({
      app_name: 'hub',
      screen_name: pageV,
    });
  }
  closeVideo() {
    this.showVideo = false;
  }
}
