




























































































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator';

import baseCard from '@/components/baseCard/baseCard.vue';
import modellerSection from '@/components/ModellerSection.vue';
import understandChanges from '@/components/UnderstandChanges.vue';
import feedbackIcon from '@/components/UI/FeedbackIcon.vue';
import feedbackForm from '@/components/input/form/FeedbackForm.vue';
import FaqsFiltered from '@/components/UI/FaqsFiltered.vue';
import ScrollToSection from '@/components/UI/ScrollToSection.vue';
import Information from '@/components/UI/Information.vue';
import Video from '@/components/UI/Video.vue';
import QuestionFeedbackWrapper from '@/components/UI/QuestionFeedbackWrapper.vue';
import FeedbackOrQuestion from '@/components/FeedbackOrQuestion.vue';
import IntroVideoHolder from '@/components/UI/IntroVideoHolder.vue';
import IntroDefaultHolderDownload from '@/components/UI/IntroDefaultHolderDownload.vue';
import { VueAgile } from 'vue-agile';

import { questionOrFeedback } from '@/observables/ui';

@Component({
  components: {
    baseCard,
    modellerSection,
    understandChanges,
    FaqsFiltered,
    feedbackForm,
    feedbackIcon,
    ScrollToSection,
    Information,
    Video,
    FeedbackOrQuestion,
    QuestionFeedbackWrapper,
    agile: VueAgile,
    IntroVideoHolder,
    IntroDefaultHolderDownload,
  },
})
export default class LandingPage extends Vue {
  public showModal = false;
  public showIntroVideo = false;
  public videoToShow = {
    type: '',
    video: '',
  };
  public sliderIndex = 0;

  public introSliderContent = [
    {
      type: 'download',
      title: 'Factsheet 1',
      subText: `The proposed GDST Flexible Pension Plan – key features`,
      tab: 'factsheet1',
      downloadLink: `/docs/2021-11 GDST_TPS_FactSheet_1.pdf`,
      downloadDocName: `2021-11 GDST_TPS_FactSheet_1.pdf`,
      imageLink: `/img/factsheet1image.PNG`,
    },
    {
      type: 'download',
      title: 'Factsheet 2',
      subText: `Using the GDST illustration tool`,
      tab: 'factsheet2',
      downloadLink: `/docs/2021-11 GDST_TPS_FactSheet_2.pdf`,
      downloadDocName: `2021-11 GDST_TPS_FactSheet_2.pdf`,
      imageLink: `/img/factsheet2image.PNG`,
    },
    {
      type: 'introVideo',
      title: 'October Update',
      video:
        'https://gdststprod.blob.core.windows.net/videos/GDST_october_update.mp4?sp=r&st=2021-12-31T10:04:55Z&se=2022-12-31T18:04:55Z&spr=https&sv=2020-08-04&sr=b&sig=JkavRKusxGtlTC1N%2BwfyMKV1uTpWtQSKuxAJoauHv4U%3D',
      tab: 'octoberupdate',
    },
    {
      type: 'introVideo',
      title: 'Introduction',
      video:
        'https://gdststprod.blob.core.windows.net/videos/GDST-Update.mp4?sp=r&st=2021-12-31T10:06:17Z&se=2022-12-31T18:06:17Z&spr=https&sv=2020-08-04&sr=b&sig=CGeNv1NLiBicAFIl1mStH17cCi2ZB0aUGBCGR5utzSg%3D',
      tab: 'introduction',
    },
  ];

  updateCarousel(index: number) {
    this.sliderIndex = index;
    const carousel = this.$refs.videoCarousel as any;

    carousel.goTo(index);
  }
  updateSliderIndex(data: any) {
    this.sliderIndex = data.currentSlide;
  }
  showFeedbackModal() {
    questionOrFeedback.showFeedbackOrQuestion = true;
  }

  closeFeedbackModal() {
    questionOrFeedback.showFeedbackOrQuestion = false;
  }

  closeModal() {
    this.showModal = false;
    this.showIntroVideo = false;
  }
  scrollToSection(classN: string) {
    const el = document.querySelector(classN) as HTMLDivElement;
    el.scrollIntoView();
    this.$emit('scrollTo', classN);
  }

  public handleShowVideo(type: string, index: number): void {
    this.videoToShow = {
      type,
      video: this.introSliderContent[index].video,
    };
    this.showIntroVideo = true;
    const pageV = `video - intro`;
    this.$gtag.screenview({
      app_name: 'hub',
      screen_name: pageV,
    });
  }

  closeIntroVideo() {
    this.showIntroVideo = false;
  }

  get isFeedbackOuestionModal() {
    return questionOrFeedback.showFeedbackOrQuestion;
  }
}
