import Vue from 'vue';
import App from './App.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faQuestionCircle,
  faPlayCircle,
  faInfoCircle,
  faChevronDoubleDown,
} from '@fortawesome/pro-regular-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';
import {
  faCheck,
  faVideo,
  faCaretDown,
  faCommentAltLines,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faTimes,
  faPlus,
  faMinus,
  faSearch,
  faAlignLeft,
  faBooks,
} from '@fortawesome/pro-light-svg-icons';
import { faSpinnerThird, faChartNetwork } from '@fortawesome/pro-duotone-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import BaseModal from '@/components/UI/BaseModal.vue';
library.add(
  faCheck,
  faInfoCircle,
  faQuestionCircle,
  faPlayCircle,
  faChevronDoubleDown,
  faTimes,
  faPlus,
  faMinus,
  faSearch,
  faAlignLeft,
  faVideo,
  faCaretDown,
  faCommentAltLines,
  faSpinnerThird,
  faBooks,
  faChartNetwork,
  faExclamationTriangle,
);

import vueNumeralFilterInstaller from 'vue-numeral-filter';

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

Vue.component('font-awesome-icon', FontAwesomeIcon);

import router from './router';

// import 'pretty-checkbox/src/pretty-checkbox.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../src/assets/scss/prettyCheckbox/customPrettyCheckbox.scss';

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import VueMq from 'vue-mq';

Vue.use(VueMq, {
  breakpoints: {
    xs: 320,
    sm: 450,
    md: 700,
    lg: Infinity,
  },
});
Vue.component('base-modal', BaseModal);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
