export const faqData = [
  {
    category: 'Recently added',
    faqs: [
      {
        question:
          'Can I transfer any accumulated pension with the new GDST scheme into another pension if I leave?',
        answer:
          '<p>If the proposals were to go ahead and you were to leave GDST after the introduction of the GDST flexible pension plan then you would be able to transfer any accumulated pension pot to another pension provider (subject to that pension plan allowing transfers). You could also transfer the pot to the TPS AVC. You may want to compare elements such as fund management charges if you were to transfer your pot but yes essentially the pot is yours to do with as you chose.</p>',
        date: '10/12/21',
      },
      {
        question:
          'Please can you explain how Aviva was selected as the provider for the proposed GDST Flexible Penson Plan.  Was there a competitive tender for the provider?',
        answer:
          "<p>Aviva were selected as the potential provider for the GDST Flexible Pension Plan following a competitive tender process supported by LCP. Aviva were recommended as the preferred provider by the Executive Team, and this recommendation was accepted by the Trustees.</p><p>The selection process involved comparing 5 potential providers across a range of criteria including cost, member support, stabiity of providers' businesses and investment.</p>",
        date: '10/12/21',
      },
      {
        question:
          'How will the Trust guarantee that the new plan invests our money ethically, in line with the ethos and priorities of the GDST (girls education and rights, diversity and inclusion, environmental considerations etc)?',
        answer:
          '<p>The proposed pension plan has a published approach to responsible investing, which includes supporting a net-zero target for 2040 and a default investment strategy that includes investing in a fund which in turn invests in companies that, in its assessment, are doing better across a range of Environmental, Social and Governance (ESG) factors.</p><p>There are also specific "Stewardship" funds which follow a three-layer process of exclusion, engagement and monitoring to ethical investing.  You can find out more about them here: <a href="https://www.aviva.co.uk/retirement/fund-centre/stewardship/" target="_blank">https://www.aviva.co.uk/retirement/fund-centre/stewardship/</a>. If you click on the "Find out more link" under "Stewardship cares" you can see that the funds aim to exclude companies with significant involvement in a number of activities which are listed.</p>',
        date: '10/12/21',
      },
      {
        question:
          'What other cost savings have been made before considering consulting to leave the TPS?',
        answer:
          "<p>We really have addressed so many areas of our operations in recent years. For example, we've targeted the schools with efficiency savings in the annual budget reviews, we've done great work through procurement in gaining benefits from our scale and also we've managed to protect ourselves from the current energy price increases (but we know these will come back to hit us hard when the current agreements run out). Part of the reason why we've had to enter this consultation phase is that we have run out of places where we can find sufficient further savings and maintain our presence as a national organisation reaching as many girls as possible - we must still invest in our staff, bursaries, technology, marketing and facilities to provide an education offer that parents, pupils and staff really value. Unfortunately we still don't generate enough free cashflow to fund what we currently need to spend, let alone should any on the headwinds we face come to bite us.</p>",
        date: '10/12/21',
      },
      {
        question: 'What happens at the end of collective consultation?',
        answer:
          "<p>At the end of collective consultation (end of January 2022), the GDST Trustees will decide if they will proceed with the proposed pension scheme. If they choose to go ahead, and a collective agreement is reached with the NEU, the terms of that agreement will be implemented for everyone involved. If it has not been possible to reach collective agreement with the NEU, the next step would be to begin individual consultation with teachers. This is where we would consult with you individually, about whether you accept the proposed scheme.</p><p>If no individual agreement has been reached and Trustees remain of the view to proceed with the change, ie. during the spring and summer terms, then a revised contract will be issued in September 2022 to take effect from January 2023.  This would give all teachers a term's notice of the change in their contract.</p>",
        date: '10/12/21',
      },
      {
        question:
          'Would withdrawing from the TPS disproportionately affect women? Will there be a gender pensions gap of 56% if the proposals go ahead, as the NEU have suggested could happen?',
        answer:
          '<p>The GDST’s proposals are the same for all of its teachers. There is no difference in the pension contributions they are proposing for women and men. So from a technical pensions perspective, there is nothing about the proposals that directly affects women more than men.</p><p>The NEU have mentioned a 56% gender pensions gap. That figure refers to some research carried out by Legal & General looking at the difference between the size of pension funds for men and women across their own client base, which will cover a whole range of industries and professions.</p><p>As a result, this figure is not directly relevant for teachers nor the GDST as a whole. That figure will be the result of a number of things, such as differences in the jobs typically carried out by women and men in the past, the effect of past gender pay gaps (I understand that the GDST had virtually no gender pay gap the last time it was assessed) and historical differences in working patterns (e.g. the impact of unpaid maternity leave, or going part-time to take on caring responsibilities). So in short, whilst it is a sobering statistic that reflects historical patterns, the 56% figure doesn’t relate to the potential impact of the GDST’s proposals for the future, which include the same pension contributions for women and men.</p>',
        date: '03/12/21',
      },
      {
        question:
          'I understand that the current employee payments are a varying percentage depending on the employee salary. Is there a reason why this has gone to a default of a flat 10%?',
        answer:
          '<p>That is correct - the current employee contribution rate to the TPS is determined with reference to an individual’s salary as shown in the table that can be <a href="https://www.teacherspensions.co.uk/members/working-life/paying-in.aspx" target="_blank">found here</a>. Employees have no influence over this contribution rate – it goes up as soon as a salary band is reached.</p><p>The proposed pay and pension options would allow a teacher to determine their own individual contribution rate at 2%, 5% ,8% or 10% or more of their pensionable salary (see the consultation guide for more details). The default would be 10% of pensionable salary (which is similar to what teachers at the GDST currently pay to the TPS on average).</p><p>The rate you choose to pay would determine the balance between how much of the GDST’s funding would go into your pension pot versus how much would be paid to you now as a pay top-up. You should be able to access the illustration tool on the consultation hub, which can illustrate what the proposed changes could mean for you both in terms of pay today and income in retirement.</p>',
        date: '26/11/21',
      },
      {
        question:
          'Why is the GDST not currently offering the option for teachers to remain in the TPS if those staff were to accept a lower salary to pay for any additional employer contributions?',
        answer:
          '<p>This is something the GDST considered as an alternative proposal.</p><p>From an employer perspective, this is a potentially difficult solution to enact. This is because under current legislation the amount of the salary reduction would potentially need to be re-consulted on every time there were a TPS valuation that changed the employer contribution rate, as this would impact on the pay cut that staff would need to take.</p><p>From a teacher perspective, this would involve them taking lower take-home pay than they already do, as well as having a career average pension based on the lower salary that would be being paid. It could also affect the value of any final salary benefits that have already been built-up. Effectively this could be a significant “double-whammy” of hits to both pay now and pay in retirement.</p><p>If you wish to bring this proposal back to the attention of the GDST Trustees then please submit a feedback form via the consultation hub using the “Have your say” button.</p>',
        date: '26/11/21',
      },
      {
        question:
          'I joined the GDST in September 2021 and wouldn’t have been in the TPS for 2 years if the GDST withdraws in January 2023.  What would happen?',
        answer:
          '<p>Our understanding is that if you were to leave the TPS after less than 2 years of pensionable service, you would have two options:</p><ol><li>Request a refund of your own contributions, which would be subject to tax. You could choose to reinvest this in an alternative pension scheme (eg the GDST Flexible Pension Plan if the proposals go ahead) if you so wished. The TPS would simply keep the GDST\'s contributions to date. OR</li><li>Leave your TPS benefits where they are. If you were to never join the TPS again your benefits would not "vest" and you would not be entitled to an annual pension from the TPS in retirement.  However, if you were to re-join the TPS in the future our understanding is that your service to date would be added to your new service (eg you might only need a further 9 months service in future eligible employment for your benefits to "vest").</li></ol><p>We understand that there would not be a direct transfer option from the TPS to the GDST Flexible Pension Plan (ie you would have to receive a refund then pay it in to any new pension scheme).</p>',
        date: '26/11/21',
      },
      {
        question:
          'Has the GDST considered offering a two-tier system (new staff in one scheme, existing staff remain in the TPS)?',
        answer:
          '<p>Unfortunately having current teachers remain in the TPS would do little to protect the organisation from the unpredictable nature of future employer contribution levels to the TPS for quite some time. With a staff turnover of 5% per year for example, it would take 15 years for this starting number of teachers to halve in number, leaving the GDST exposed to many years of the contribution levels that are already contributing to the financial challenges currently being faced. If you wish to bring this proposal back to the attention of the GDST Trustees then please submit a feedback form via the consultation hub using the “Have your say” button.</p>',
        date: '26/11/21',
      },
      // {
      //   question: 'What other cost-cutting measures were implemented before looking at pensions?',
      //   answer: '<p>Good examples of this are that we\'ve targeted the schools with efficiency savings in the annual budget process, we\'ve done great work through procurement to use the size of the GDST to negotiate better contracts and also we\'ve managed to protect ourselves from the current energy price increases (but we know these will come back to hit us hard when the current agreements run out).</p>',
      //   date: '26/11/21'
      // },
      {
        question:
          'What happens towards the end of December 2022 if there is still no agreement with teachers/the NEU as to how best to proceed?',
        answer:
          "<p>Should the GDST Trustees decide to go ahead with the proposals either in their current form or having been amended following feedback from teachers and/or negotiation with the NEU, the GDST's preferred option is to do this by collective agreement with the NEU.  In the event that a collective agreement is not possible, the GDST would be hopeful of obtaining colleagues' individual agreement to a change terms and conditions if the GDST Trustees’ decision is to do so.</p>",
        date: '26/11/21',
      },
      {
        question:
          'What control would I have over the investment risks taken in the proposed pension scheme?',
        answer:
          '<p>The proposed GDST Flexible Pension Plan would be part of the Aviva "Master Trust". In a Master Trust pension scheme, the investments are ultimately the responsibility of the Trustees of the Master Trust. The suitability and track record of the investment options was considered when selecting Aviva as the potential provider for the GDST Flexible Pension Plan.</p><p>There would be a "default" investment option which, broadly speaking, would mean that as you get closer to retirement your pension pot would gradually be switched to assets deemed to present lower risk.  You would, however, be able to select your own funds from a selected range should you wish to. The list of funds can be found <a href="https://library.aviva.com/tridion/documents/view/sp98338.pdf" target="_blank">here</a>.  Each fund has a "risk rating" from 1 (lowest volatility) to 7 (highest volatility).  You can find Aviva\'s guide to their risk ratings <a href="https://library.aviva.com/tridion/documents/view/in90149.pdf" target="_blank">here</a>.</p><p>In direct answer to the question, you could therefore control the amount of risk by allocating your pot to certain funds. However, investing money always means there is a level risk.</p>',
        date: '26/11/21',
      },
      {
        question:
          'How do the investment options in the GDST Flexible Pension Plan consider the environment?',
        answer:
          '<p>The proposed pension plan has a published approach to responsible investing, which includes supporting a net-zero target for 2040 and a default investment strategy that includes investing in a fund which in turn invests in companies that, in its assessment, are doing better across a range of Environmental, Social and Governance (ESG) factors.</p><p>There are also specific "Stewardship" funds which follow a three-layer process of exclusion, engagement and monitoring to ethical investing.  You can find out more about them <a href="https://www.aviva.co.uk/retirement/fund-centre/stewardship/" target="_blank">here</a>. If you click on the "Find out more link" under "Stewardship cares" you can see that the funds aim to exclude companies with significant involvement in a number of activities which are listed.</p>',
        date: '26/11/21',
      },
      {
        question: 'I don’t understand whether the GDST is in a healthy state financially or not.',
        answer:
          '<p>The operating surplus for 2019/20 (reported as "Net income before gains and losses on investment") was £8.2m, down from £14.9m the year before. This is significantly below what the GDST needs to invest in the educational offer across the GDST schools.</p><p>However, it should be noted that the published GDST accounts are a consolidation of all elements of the GDST family, which includes the 2 GDST academy schools, which operate differently and are funded by the government. As a Charitable Trust the Charity Commission requires that we keep a suitable level of reserves that enable us to remain secure in uncertain times. The GDST must also invest in its people, bursaries, marketing, technology and facilities to keep the schools vibrant and competitive, as well as providing an educational offer that parents value. After the day to day running of our schools GDST requires £30m a year to keep the Trust operational, that’s needed not just for the condition and compliance of our estate, but also the creation of exciting learning spaces and our desire to invest more in ed-tech and other future initiatives. In 2020/21 we were £9m short of this and we predict that we could be £19m short in 3 years’ time.</p>',
        date: '26/11/21',
      },
      {
        question:
          'What are the Aviva funds invested in for Low, Medium and High returns on the illustration tool? How frequently can you change from one option to another and the level of risk taken?',
        answer:
          '<p>The Low, Medium and High assumed returns in the illustration tool are intended to help you understand how different investment outcomes might affect any pension pot in the GDST Flexible Pension Plan rather than being illustrations of specific funds.  Historically and on average, higher risk investments have typically achieved higher investment returns over the longer term but with more "volatility" along the way.  As always, past performance is not necessarily a good guide to future performance.</p><p>You would be able to move your money in your pot as and when you please. Please be aware that there may well be implications of moving between funds including but not limited to any "transaction costs" and time out of the market associated with buying and selling assets.  Different funds could have different annual management charges too.</p><p>There would be a "default" investment option which, broadly speaking, would mean that as you get closer to retirement your pension pot would gradually be switched to assets deemed to present lower risk.  You would, however, be able to select your own funds from a selected range should you wish to. The list of funds can be found <a href="https://library.aviva.com/tridion/documents/view/sp98338.pdf" target="_blank">here</a>.  Each fund has a "risk rating" from 1 (lowest volatility) to 7 (highest volatility).  You can find Aviva\'s guide to their risk ratings <a href="https://library.aviva.com/tridion/documents/view/in90149.pdf" target="_blank">here</a>.</p><p>Please note that investing money always means there is a level risk.</p>',
        date: '26/11/21',
      },
    ],
  },
  {
    category: 'The consultation',
    faqs: [
      {
        question: `What is consultation?`,
        answer: `<p>It is an open discussion an employer has with its staff and the recognised trade union about proposed changes to employment contracts, with a view to reaching an agreement.</p>
        <p>As the GDST recognises the NEU for collective consultation purposes, there is a legal obligation to consult with them.</p>
        <p>Consultation is two-way.  The employer explains details of the proposed changes, and affected staff share their views, concerns, and any ideas for alternatives before any decisions are made.</p>`,
      },
      {
        question: `Why has a change to teacher pension provision been proposed?`,
        answer: `<p>Full details of the rationale for the proposed changes are set out in the initial communication documents. Copies of these can be accessed on the consultation hub.</p>
        <p>GDST currently provides pensions to teachers through the Teachers’ Pension Scheme (TPS).  The cost of allowing teachers to continue to build up benefits in the TPS increased by over 40% in September 2019. In monetary terms this meant an increase in costs to the GDST of £6m per year. This increase in costs, over which the GDST had no control, caused the Trustees to consider how best to provide pension provision to teaching staff in the GDST.</p>
        <p>It is important that the Trustees receive and understand your feedback, as well as that of the NEU, so that they can take it into account in their decision-making process.</p>`,
      },
      {
        question: `Who is affected by the consultation?`,
        answer: `<p>All teachers in all GDST schools, excluding the Academies, who are either current active members of the TPS or who have previously opted out of the TPS. In summary this is any member of staff whose contract refers to membership of the Teachers’ Pension Scheme.</p>`,
      },
      {
        question: `Are non-teaching staff affected?`,
        answer: `<p>No – only teachers are affected by the consultation as non-teaching staff are not eligible to join the TPS.</p>`,
      },
      {
        question: `Who will make the final decision regarding pension provision going forwards?`,
        answer: `<p>Once we have received and considered your feedback, and concluded collective consultation with the NEU,  the Trustees will decide whether to go ahead with the proposed changes. The Trustees will also consider whether to make any changes or updates to the proposed changes.</p>`,
      },
      {
        question: `How can I provide feedback on the proposal?`,
        answer: `<p>Our collective consultation is with the NEU as our recognised union. You can provide feedback through your NEU representatives.  You can also provide feedback via the online form on the consultation hub. This feedback will be seen by GDST in the exact format that you submit it and may be shared with the NEU on a non-attributable basis.</p>`,
      },
      {
        question: `What are other independent schools doing?`,
        answer: `<p>We understand that as at September 2021 more than 270 other independent schools have already exited the TPS or indicated their intention to leave, and that many more are currently going through consultation processes to decide whether to exit.</p>`,
      },
      {
        question: `What are maintained schools doing?`,
        answer: `<p>Teachers in maintained schools are currently provided with pension benefits from the TPS. The Treasury provided maintained schools with direct funding to meet the increase in pension contributions in September 2019. This funding was not provided to independent schools.</p>
        <p>We are not aware of proposed changes to the pension benefits provided to teachers in maintained schools.</p>`,
      },
    ],
  },
  {
    category:
      'How a defined benefit pension scheme (such as the TPS) compares to a defined contribution pension scheme',
    faqs: [
      {
        question: `What is a defined benefit pension scheme?`,
        answer: `<p>In a ‘defined benefit’ scheme, such as the TPS, members are promised a benefit in retirement based on a combination of their pensionable salary, how long they have been in the scheme (known as pensionable service), and an ‘accrual rate’ (which determines the pace at which benefits are built-up).  The benefits are defined by a formula.</p>
        <p>‘Final salary’ schemes and ‘career average revalued earnings’ schemes are types of ‘defined benefit’ schemes.</p>
        <p>As a TPS member, the way(s) in which your annual pension will be calculated depends on your age and the date at which you joined the TPS.  You can find out which benefits you have by logging into My Pension Online on the <a href="https://www.teacherspensions.co.uk/public/login.aspx" target="_blank">TPS website</a>.</p>
        <p>For those of you with ‘final salary’ benefits, your annual pension is calculated based on your years of pensionable service, your accrual rate (which can be different for different members) and your final pensionable earnings before your retirement date or before you leave pensionable service for another reason e.g. if you leave teaching.  If you leave pensionable service before you retire, some inflation protection is provided to your pension up to the date that you retire.</p>
        <p>For those of you with ‘career average’ benefits, your annual pension is calculated based on the accrual rate and your pensionable earnings in each year in which you are an active member of the scheme. The amount of pension that you build up each year has inflation protection added at the end of each year. If you leave pensionable service before you retire, some inflation protection is provided to your pension up to the date that you retire.</p>
        <p>Defined benefit schemes are usually paid for with a mixture of member and employer contributions.</p>
        <p>Member contributions are normally made as a fixed percentage of salary (the TPS has tiered contributions of between 7.4 and 11.7% of pensionable pay depending on your salary band, as set out in the TPS regulations). Employer contributions change dependent on how much is expected to be required in the long term to be able to pay everyone’s pension benefits (see question14). In the TPS this is 23.68% of pensionable pay with effect from 1 September 2019 and is normally subject to review every 4 years.</p>
        <p>Ultimately, the financial risk of paying the pensions in the TPS sits with the employers participating in the TPS and, because it is an unfunded public sector scheme, the taxpayer. This is because the ultimate cost of providing the pensions (which can be thought of as a salary in retirement) is unknown – it depends on factors such as how long people live.</p>
        <p>Members of defined benefit pension schemes (including the TPS) normally have the option to take part of their pension as a cash lump sum at retirement (payable tax free under current legislation up to a certain limit).</p>
        <p>Some members of TPS have a right to an automatic lump sum at retirement.  TPS members may also be able to retire before their normal retirement age, although their annual pension would generally be reduced to take account of the fact that it will be paid for longer.</p>`,
      },
      {
        question: `What does the TPS being “unfunded” mean?`,
        answer: `<p>Unlike most private sector defined benefit schemes, the TPS does not have a pot of assets set aside to pay pensions.  Instead, it operates on a “pay as you go” or “unfunded” basis – this means that the pensions for current pensioners are paid for by contributions from current teachers and schools, with a top-up or refund to the Exchequer as required.</p>
        <p>Despite being unfunded, the TPS is typically considered a secure source of retirement income because it is backed by the Government.</p>
        `,
      },
      {
        question: `What is a defined contribution scheme?`,
        answer: `<p>In a defined contribution scheme both the member and the employer pay into a member’s personal pension pot. The contributions are invested with the aim that they grow over the long-term until the member is ready to take them. The member has the opportunity to choose how the contributions are invested. When the member is ready to access their personal pension pot there are a range of options. The benefits are not guaranteed and as with any investment, the value of the personal account can go down as well as up.</p>
        <p>In a defined contribution scheme members have flexibility in how to draw their pension benefits, including: </p>
        <ul><li>Full access to all of a member’s funds immediately, provided they have reached the minimum pension age, currently 55 (and expected to increase to age 57 in 2028. You could take the whole amount of your pension pot as cash, but anything over the first 25% would be subject to income tax.</li>
        <li>A member can buy an annuity (a regular income for life) if they wish to do so with all or part of their fund. There are different lifetime annuity options and features to choose from that affect how much income you would get, and you will be able to buy this from another pension provider if you choose. You can also choose to provide an income for life for a dependent or other beneficiary after you die.</li>
        <li>A member can keep the funds invested and access them gradually over time, withdrawing some money as and when required (often referred to as drawdown). You could choose to take a series of cash sums or income from your retirement pot over time whilst the balance remains invested.</li>
        </ul>
        <p>As such, a defined contribution scheme can be considered later life savings rather than a salary in retirement.  It is important to note that any income from pension benefits other than the 25% tax-free allowance is typically subject to income tax.</p>
        `,
      },
    ],
  },
  {
    category: 'Teachers’ Pension Scheme (TPS)',
    faqs: [
      {
        question: `Are state school teachers eligible to join the TPS?`,
        answer: `<p>Teachers who work in schools in England and Wales which are maintained by a local authority are eligible to join the TPS. There is a separate scheme for teachers in Scotland.</p>`,
      },
      {
        question: `What happens if I leave the TPS with less than 2 years of membership?`,
        answer: `<p>If you leave the TPS with less than 2 years of scheme membership, then you have the option to claim a refund of your total contributions to the scheme.</p>
        `,
      },
      {
        question: `When can I retire from the TPS?`,
        answer: `<p>Unless you opt to retire early, your benefits would come into payment from a date between your 60th birthday and your State Pension Age, depending on when you joined the TPS. For many teachers, the State Pension Age is currently expected to be between 66 and 68.</p>
        <p>Further details can be found <a href="https://www.teacherspensions.co.uk/members/planning-retirement/when-can-you-retire.aspx" target="_blank">here.</a></p>`,
      },
      {
        question: `Why have the GDST’s TPS costs increased?`,
        answer: `<p>Every four years the Government Actuary’s Department carries out a valuation of the TPS.  This valuation has two purposes:</p>
        <ul><li>To assess the value of the benefits members have already built up (in effect, the future liabilities the TPS has promised) and compare this with the value of a notional pot of assets set aside (“notional” because there are no actual assets as the TPS is unfunded – see question 9).  If there is a shortfall in the value of the notional assets compared with the liabilities, there is a deficit, and the employers are required to make additional “deficit contributions” to make good the shortfall.</li>
          <li>To assess how much employers and employees are required to pay to fund the benefits as they are earned from year to year (“future contribution rates”).</li>
        </ul>
        <p>The results of the most recent valuation as at 31 March 2016 were as follows:</p>
        <ul><li>Deficit contributions:  As at 31 March 2016 the total liabilities in the TPS were calculated to be some £220 billion whilst the notional assets were around £200 billion. There was therefore a shortfall in the notional assets compared with the liabilities of around £20 billion.  Of the 23.68% of salaries that schools are now paying towards the TPS, 7.2% is intended to cover this notional deficit.</li>
            <li>Future contribution rates:  The contribution rates also increased significantly due to a number of factors, including lower expectations of future UK economic growth.</li>
            <li>Overall:  School contribution rate increased from 16.48% of salaries to 23.68% of salaries.</li>
        </ul>`,
      },
      {
        question: `What is the uncertainty around TPS benefits earned since 2015?`,
        answer: `<p>There is some uncertainty around the benefits the TPS provides due to recent legal cases, often referred to as the McCloud case.  Changes made to various public service pension schemes (including the TPS) from 2015 onwards meant different things for different people based on their age, with those close to retirement (less than 13.5 years away from their Normal Pension Age in April 2012) protected against the full impact of the changes.  The result of the McCloud case was that these changes were deemed to breach age discrimination law, and so some kind of remedy is required.</p>
        <p>The Government has confirmed that its  approach to fixing this issue will involve the following:</p>
        <ul>
          <li>From April 2022 all members will move to the post-2015 benefit scale for future service;</li>
          <li>Every affected member of the TPS will be offered a choice between benefits earned on the new or old benefit scales in respect of service between 2015 and 2022.</li>
        </ul>
        <p>Rectifying this situation is expected to increase (not decrease) costs, although the issue will take some time to be resolved.</p>
        <p>You can find out more about this issue <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/958190/Final_Leaflet.pdf" target="_blank">here</a>.</p>
       `,
      },
    ],
  },
  {
    category: 'How the GDST’s proposed changes would affect your benefits',
    faqs: [
      {
        question: `When will the proposed changes come into effect?`,
        answer: `<p>The target date for the proposed changes is 1 January 2023.</p>`,
      },
      {
        question: `What would happen to the benefits I have already accrued within the TPS?`,
        answer: `<p>As a current active member of the TPS you would become a deferred member of the TPS at the date GDST exist the TPS, which would be 31 December 2022 under the proposal.  If you have more than two years’ service in the TPS, the benefits you have already built up would be preserved in the TPS until you come to draw your pension. Any final salary benefits would be based on your final salary at the date of exit, rather than at your retirement date.  Your preserved benefits would then be increased to provide protection against the effects of inflation in accordance with the TPS rules and relevant legislation.</p>
        `,
      },
      {
        question: `How would I build up additional benefits in the future?`,
        answer: `<p>Under the proposed changes, future pension contributions from both you and the GDST would be paid into a defined contribution pension scheme called GDST Flexible Pension Plan.</p>
        <p>When you retire you would receive benefits from GDST Flexible Pension Plan as well as from the TPS.</p>`,
      },
      {
        question: `What is the GDST Flexible Pension Plan?`,
        answer: `<p>The GDST Flexible Pension Plan is a defined contribution pension scheme.  It is set up as a trust and has an independent board of Trustees.</p>
        <p>It is a separate pension arrangement from the TPS and would be run and administered by Aviva.</p>`,
      },
      {
        question: `What contributions would I be required to pay into the GDST Flexible Pension Plan?`,
        answer: `<p>There are four employee contribution rate options of 2%, 5% ,8%, and 10% of your pensionable salary. You would be able to choose how much you want to contribute, and the choice you make will impact how much the GDST pays into your pension versus how much they put into your pay now.  The minimum contribution rate would be 2% of your salary, however you may choose to pay a higher level of contributions if you wish.  If the proposed changes are implemented and you do not choose your own contribution rate, you would be automatically enrolled at a contribution rate of 10% of your salary.</p>`,
      },
      {
        question: `Would I be able to pay AVCs?`,
        answer: `<p>Yes – you would be able to pay a higher contribution rate if you wish to, up to 100% of your salary.  These would be treated like your other contributions and invested in your investment pot.</p>`,
      },
      {
        question: `What contributions would the GDST pay into the GDST Flexible Pension Plan?`,
        answer: `<p>The GDST would pay contributions based on how much you contribute up to the maximum contribution of 18% of pensionable salary.  The potential contribution scales under the proposal are: </p>
        <p>
          <div class="faq-table align-center col-4 cell-border">
            <div class="tr">
              <div class="th cell-border align-center">Pay and pension option</div>
              <div class="th cell-border align-center">Employee</div>
              <div class="th cell-border align-center">Employer</div>
              <div class="th cell-border align-center">Total into Pension</div>
              <div class="th cell-border align-center">Non-pensionable pay top-up</div>
            </div>
            <div class="tr table-highlight">
              <div class="td cell-border align-center">Pension max</div>
              <div class="td cell-border align-center">10%+</div>
              <div class="td cell-border align-center">18%</div>
              <div class="td text-bold cell-border align-center">28%</div>
              <div class="td cell-border align-center">0%</div>
            </div>
            <div class="tr">
              <div class="td cell-border align-center">Pension plus</div>
              <div class="td cell-border align-center">8%</div>
              <div class="td cell-border align-center">16%</div>
              <div class="td text-bold cell-border align-center">24%</div>
              <div class="td cell-border align-center">2%</div>
            </div>
            <div class="tr">
              <div class="td cell-border align-center">Pay plus</div>
              <div class="td cell-border align-center">5%</div>
              <div class="td cell-border align-center">12%</div>
              <div class="td text-bold cell-border align-center">17%</div>
              <div class="td cell-border align-center">6%</div>
            </div>
            <div class="tr">
              <div class="td cell-border align-center">Pay max</div>
              <div class="td cell-border align-center">2%</div>
              <div class="td cell-border align-center">8%</div>
              <div class="td text-bold cell-border align-center">10%</div>
              <div class="td cell-border align-center">10%</div>
            </div>
          </div>
        </p>
        <p>The default would be the Pension max option. This is the level that employees would be placed into if they did not choose an option themselves.</p>`,
      },
      {
        question: `What is the pay top-up option?`,
        answer: `<p>The proposal gives you the option to take additional pay top-up in place of part of the contribution that could be paid into your pension pot by the GDST.  Neither you nor the GDST would pay pension contributions on this additional salary – known as a non-pensionable pay top-up – but it would attract income tax and national insurance as additional income.</p>
        <p>The proposal is that the total GDST contribution budget would be 18% of your pensionable salary but this could be split between pension contributions and a pay top-up with the level based on which pay and pension option you choose.</p>`,
      },
      {
        question: `Can I opt for the GDST’s contributions to be paid into a different Defined Contribution scheme?`,
        answer: `<p>No – under the proposal the GDST would only pay contributions into the GDST Flexible Pension Plan.</p>`,
      },
      {
        question: `Can I change my chosen pay and pension option at a later date?`,
        answer: `<p>Yes – under the proposal you would be allowed to change your pay and pension options each March. All pension scheme members will be contacted annually and given the option to change or roll over their  pay and pension option.</p>
        <p>It will also be possible for scheme members to change their pay and pension option in the event of a major life event.</p>`,
      },
      {
        question: `What are the investment choices?`,
        answer: `<p>You can find out more about the <a href="https://workplace.aviva.co.uk/gdst-flexible-pension-plan" target="_blank">investment choices</a></p>
        <p>If you don’t make a choice there is a default option.  The default option is a lifestyle option so the investments change as a member approaches their chosen retirement age.  There is more information on the default  that can be found using the link above.</p>`,
      },
      {
        question: `What are the charges?`,
        answer: `<p>In the GDST Flexible Pension Plan members pay a small charge for the management of their investment pot (called an Annual Management Charge).  These charges vary based on your investment choices.  The charge in the default investment option is currently 0.27% of your retirement savings account each year.  You can also choose different investment options, and these would attract different charges, depending on where they invest.  Management charges are taken out of your retirement account, so it would not affect how much you contribute.</p>`,
      },
      {
        question: `What options would I get from the GDST Flexible Pension Plan at retirement?`,
        answer: `<p>You would have several retirement options available to you from the GDST Flexible Pension Plan</p>
        <p>Options currently available at retirement include:</p>
        <ul>
          <li>taking your account as a single cash lump sum;</li>
          <li>using your account to buy a pension (or annuity) to give you an income for the rest of your life; and</li>
          <li>accessing your account as a flexible source of retirement income, for example by using a drawdown arrangement</li>
        </ul>
        <p>Under current tax rules, most members have the option to take up to 25% of their fund tax-free either as a lump sum or if opting for drawdown this can be spread out. </p>
        `,
      },
      {
        question: `What happens if I leave the GDST before retirement?`,
        answer: `<p>Under the proposed changes, your protected deferred TPS benefits would not be affected by your decision to leave the GDST.</p>
        <p>Your contributions in the GDST Flexible Pension Plan would remain invested and change in value in line with how those investments perform.</p>
        <p>Your GDST Flexible Pension Plan benefits would be portable, so you could transfer them elsewhere if you have a registered pension provider that would accept a transfer value.  This might be a defined contribution scheme offered by another employer or a personal pension that you set up. You may also be able to transfer your TPS benefits to another defined benefit pension scheme, if you were to become a member of such a scheme through other employment.  If you are considering transferring your benefits, we strongly recommend that you take financial advice.</p>
        `,
      },
      {
        question: `Would I continue to be eligible for a death in service lump sum?`,
        answer: `<p>The TPS currently provides a death in service lump sum of 3 times your annual full time equivalent salary.</p>
        <p>Under the proposal, you would be eligible for a death in service lump sum of 4 times your pensionable salary.</p>
        `,
      },
      {
        question: `Would I continue to be eligible for a TPS death in service pension?`,
        answer: `<p>The TPS currently provides a death in service pension to your surviving spouse and/or children.  The amount will depend on whether you are currently a ‘final salary’ member or a ‘career average revalued earnings’ member.  As a deferred member of the TPS, a pension would also be payable to your spouse and/or children, however, this would generally be lower than if you were an active member.</p>
        <p>Further details of the benefits payable on death in service from the TPS can be found <a href="https://www.teacherspensions.co.uk/-/media/documents/member/factsheets/family-and-dependents/death-benefits.ashx" target="_blank">here</a>.</p>
        <p>The value of your GDST Flexible Pension Plan pot would usually be paid to your nominated beneficiaries (and would generally be paid free of inheritance tax). Additional benefits could also be secured from your funds in the GDST Flexible Pension Plan, so in aggregate the position could be better or worse.</p>
        `,
      },
      {
        question: `Would I continue to be eligible for benefits in long term ill-heath?`,
        answer: `<p>Under the rules of the TPS, as an active member you can apply for an ill-health pension at any time during your career providing you are under the normal pension age for the pension benefits that you wish to take and you have at least 2 years’ qualifying service.</p>
        <p>As an active member you may be entitled to a ‘Total incapacity pension’ (often referred to as a ‘Tier 2’ pension), which includes an enhancement to your pension benefit of 50% of prospective pension up to normal pension age, providing you fulfil the qualifying criteria as set out in the TPS regulations. These benefits are usually subject to medical opinion. If you do not qualify for a Tier 2 pension, then you may still be entitled to a Tier 1 ill-health pension (if you are unable to teach) based on your accrued pension in the TPS to the date of leaving.</p>
        <p>Under the proposal, the GDST would pay for the cost of income protection, which would cover 50% of your salary for up to your state pension age after your standard sick pay ceases.  This benefit may be subject to change in the future if the cost of insuring this benefit, alongside the death in service lump-sum, exceed 2% of salaries.</p>
        <p>You may also be able to access the funds in your personal account in GDST Flexible Pension Plan, subject to meeting the scheme’s ill-health criteria.</p>
        <p>You may still also be entitled to an ill-health pension from the TPS as a deferred member providing you qualify under the rules of the TPS.  However, it would be based only on your accrued pension benefits with no enhancement if you meet the criteria for a ‘Total incapacity pension’.  If you only meet the criteria for a Tier 1 pension (unable to teach) there is no enhanced benefit – you may be able to draw your pension from the minimum pension age (currently age 55), subject to a reduction to reflect the fact that it is being paid earlier.</p>
        <p>Further details of the criteria for ill-health retirement and details of the benefits payable from the TPS can be found <a href="https://www.teacherspensions.co.uk/members/planning-retirement/types-of-retirement/ill-health.aspx" target="_blank">here</a>.</p>
        `,
      },
      {
        question: `What would happen if I rejoin the TPS at another school, having left the TPS whilst working at a GDST school?`,
        answer: `<p>Details regarding the benefits payable following a break in service can be found <a href="https://www.teacherspensions.co.uk/members/faqs/taking-a-break/leaving-or-taking-a-break-from-the-scheme.aspx" target="_blank">here</a>.</p>
        `,
      },
      {
        question: `What happens if I retire early? How much will TPS my benefits be reduced?`,
        answer: `<p>If you retire early, then your TPS benefits are reduced to reflect that you are expected to receive them for a longer period.  Very broadly, the current reduction is 15-25% if you retire 5 years early (although this may change in the future), however the actual reduction depends on what type of member you are.</p>
                <p>Under the GDST Flexible Pension Plan the benefits payable to you on retirement are whatever funds are available in your Defined Contribution pot at that date. There is no explicit reduction applied to your benefits, however the earlier you retire the lower your fund is likely to be as you would miss out on future contributions and investment returns. It is worth noting though that under drawdown the funds left in your account would continue to be invested and could therefore achieve further investment returns.</p>
        `,
      },
      {
        question:
          'Would we miss out in terms of years of service if we were to leave the TPS in January?  Might the swap be made at the end of the academic year instead?',
        answer:
          '<p>Pensionable service within the TPS is calculated on a years and days basis. In other words, you build-up a little bit of pension for every day you are an active member rather than service "jumping up" by a year annually.</p><p>Any amendments to the proposals you might wish the GDST to consider can be submitted using the “Have Your Say" form on the consultation hub or by speaking to your NEU representative.</p>',
        date: '19/11/21',
      },
      {
        question:
          'Will the proposed new scheme accept transfers in from other (non-TPS) pensions (eg my company pensions I got when working in business before becoming a teacher)?',
        answer:
          '<p>Yes the proposed scheme would accept transfers in from other pension arrangements subject to those plans allowing transfers.</p>',
        date: '19/11/21',
      },
      {
        question:
          'Please could you explain what the investment options under the GDST Flexible Pension Plan would be?',
        answer:
          '<p>There is a high-level introduction to the investment options that would be available if the proposals go ahead in the 4th video guide on the consultation hub (called "Pay and pension options"). If you’d like to find out more you might find the website Aviva have set-up useful: <a href="https://workplace.aviva.co.uk/gdst-flexible-pension-plan/" target="_blank">https://workplace.aviva.co.uk/gdst-flexible-pension-plan/</a> - towards the bottom of the page in the "Documents" section there is a link to a document that contains the full list of the funds that would be available, the charges associated with each of them, and a link to a factsheet on each fund including past performance figures.</p>',
        date: '19/11/21',
      },
      {
        question:
          'How would dependent children be catered for under the GDST Flexible Pension Plan?',
        answer:
          '<p>If the proposals were to go ahead, a specific pension for any eligible dependent children would still be payable in respect of any TPS benefits that had already been built-up. Any dependent children could also receive 100% of the value of any pot in the GDST Flexible Pension Plan and/or the 4x salary death in service lump-sum proposed if you were to choose them as your nominated beneficiaries. In other words, there would be no new specific protection proposed for dependent children but they could be your chosen beneficiaries.</p>',
        date: '19/11/21',
      },
      {
        question:
          'The TPS currently has a minimum membership term of 2 years (if I pull out prior to 2 years, all contributions are returned net of tax). What’s the position for the GDST Flexible Pension Plan? Is there a minimum term to qualify, or would all contributions “vest” from day 1?',
        answer:
          '<p>Under current rules, your benefits would "vest" after 30 days. So even if the proposals were to go ahead and you were only in the GDST Flexible Pension Plan for a few months, you’d still get have a pension savings pot based on contributions paid by both you and the GDST.</p>',
        date: '19/11/21',
      },
    ],
  },
  {
    category: 'The illustration tool',
    faqs: [
      {
        question: 'Is the lump sum at retirement in the illustration tool adjusted for tax?',
        answer:
          '<p>Under current tax rules, you would be entitled to take a tax-free cash lump-sum in both the TPS and the proposed GDST Flexible Pension Plan.  The maximum tax-free lump-sum you could take under current tax rules in either pension scheme is typically around 25% of the value of your benefits.  In the GDST Flexible Pension Plan you would be able to take a lump-sum larger than 25% of your pot, but under current tax rules any amount above the first 25% (for most people) would be subject to income tax. The illustration tool is showing an estimated maximum tax-free lump-sum.</p><p>Please note that some individuals in the TPS have an "automatic cash lump-sum" in respect of the "80th Final Salary Arrangement".  This has been allowed for in the illustration tool.</p>',
        date: '19/11/21',
      },
      {
        question:
          'On the options for Pension Max through to Pay Max, is this something that can be changed regularly to be flexible; eg is it for a year on one level?',
        answer:
          '<p>Yes, you would be able to elect to an option every year, for implementation in April each year. However should you have a significant life event, such as having a baby, or significantly changed personal circumstances, then you would be able to change your option mid year as well.</p>',
        date: '19/11/21',
      },
      {
        question:
          'The GDST illustration tool gives me a considerably lower estimate of my TPS pension than the TPS’s own calculator does. Why is this?',
        answer:
          '<p>We have investigated the reasons for differences between these two tools. The outputs from the GDST illustration tool cannot be compared directly with the outputs from the TPS’s own calculators, principally because the TPS’s own calculators appear to provide some figures based on what “future money” may look like. The GDST illustration tool shows all pension figures in “today’s money”. In other words, it estimates how much your pension might buy you in today’s terms. Other calculators may show you what you might get in “future money” figures in some circumstances. These figures are higher because they do not allow for how inflation can reduce the "buying power" of money. There are also likely to be a number of other assumption and calculation differences between any two tools.  For example, if your pension in 20 years’ time is expected to be £40,000 per year, if inflation has been 2.5% per year, this would only have the same buying power as less than £25,000 does today.  The GDST illustration tool would show you the lower figure, i.e. that represents the buying power of today’s money.</p>',
        date: '19/11/21',
      },
      {
        question:
          'As a part timer, when I’m asked to enter “As a percentage, what proportion of your full time hours do you work?”, the % is rounded. How does this impact my illustration and benefits?',
        answer:
          '<p>You are still able to enter a non-round number (eg 66.7 for 66.7%) and this will carry through into the illustration tool itself. Please note, however, that the outputs from the illustration tool itself are rounded as its for illustrative purposes only.</p>',
        date: '19/11/21',
      },
      {
        question:
          'Would you clarify that when the illustration tool on the consultation hub gives an annuity amount, this would then rise with inflation?',
        answer:
          '<p>Yes we confirm that the annuity assumed to be purchased would rise with inflation. Further details about the annuity assumed to be purchased can found in the “Assumptions” tab of the Illustration Tool.</p>',
        date: '19/11/21',
      },
    ],
  },
  {
    category: 'Other questions',
    faqs: [
      {
        question: `Will I be able to transfer funds from another DC Scheme into GDST Flexible Pension Plan`,
        answer: `<p>The GDST Flexible Pension Plan allows you to transfer in funds from other schemes, if the transfer is allowed from your existing scheme.  The process is relatively straightforward.</p>`,
      },
      {
        question: `What fees are payable to GDST Flexible Pension Plan?`,
        answer: `<p>Management and other fees will be deducted directly from your account and will depend on the investment choices that you make.  Based on the current investment choices available, the annual investment charges will be 0.23%-1.39% of your fund.  The current charge for the “default” is 0.27% pa.</p>
        <p>Full details of the investment charges can be found <a href="https://library.aviva.com/tridion/documents/view/sp98338.pdf" target="_blank">.</p>
        `,
      },
      {
        question: `What fees are payable to GDST Flexible Pension Plan?`,
        answer: `<p>Management and other fees will be deducted directly from your account and will depend on the investment choices that you make.  Based on the current investment choices available, the annual investment charges will be 0.23%-1.39% of your fund.  The current charge for the “default” is 0.27% pa.</p>
        <p>Full details of the investment charges can be found <a href="https://library.aviva.com/tridion/documents/view/sp98338.pdf" target="_blank">here</a>.</p>
        `,
      },
      {
        question: `Who are the NEU representatives?`,
        answer: `<p>The GDST has a Joint Consultative Group with the NEU. The NEU representatives on the committee are:</p>
        <p>
          <div class="faq-table col-2 ">
            <div class="tr">
              <div class="td">John Richardson</div>
              <div class="td">NEU National Official for the Independent Sector</div>
            </div>
            <div class="tr">
              <div class="td">Kester Brewin</div>
              <div class="td">Sydenham, and NEU Staff Side Chair and NEU GDST Branch Secretary</div>
            </div>
            <div class="tr">
              <div class="td">Sadaf Choudhry</div>
              <div class="td">Streatham & Clapham</div>
            </div>
            <div class="tr">
              <div class="td">Kay Gray</div>
              <div class="td">Newcastle</div>
            </div>
            <div class="tr">
              <div class="td">Paul Ivison</div>
              <div class="td">Newcastle (support staff)</div>
            </div>
            <div class="tr">
              <div class="td">Jenny Jones</div>
              <div class="td">Howell’s</div>
            </div>
            <div class="tr">
              <div class="td">Jacq Kothare</div>
              <div class="td">Northwood</div>
            </div>
            <div class="tr">
              <div class="td">Sarah Williams</div>
              <div class="td">Blackheath</div>
            </div>
          </div>
        </p>
        <p>The NEU will decide which representatives will be involved in the collective consultation about the Teachers’ Pension Scheme</p>
        <p>In addition, most schools have an elected NEU representative.  The school representatives are:</p>
        <ul class="faq-list">
          <li>Sally Potter – Bath</li>
          <li>Grant Sherwood - Brighton</li>
          <li>Johan Vilgoen - Bromley</li>
          <li>George Flower - Croydon</li>
          <li>Daniel Goodwin & Matt Bradshaw - Norwich</li>
          <li>Sandra Craggs & Andy Gill – Notting Hill & Ealing</li>
          <li>Emma Furness – Nottingham</li>
          <li>Rachel Burton, Dani Phipps & Emma Regardsoe – Oxford</li>
          <li>Michael Davis – Portsmouth</li>
          <li>Kate Jeffrey – Putney</li>
          <li>Jane Cooke & Ruth Crowther – Sheffield</li>
          <li>Maria Harding & Bill Hammond – Shrewsbury</li>
          <li>Amanda Johnson & Vicky Spawls – South Hampstead</li>
          <li>Jackie Davis – Sutton</li>
          <li>Sarah Lindon - Wimbledon</li>
        </ul>
        `,
      },
    ],
  },
  {
    category: 'Further information and advice',
    faqs: [
      {
        question: `Where can I get further information?`,
        answer: `<p>There is a lot more information, including an illustration tool on the consultation hub.</p>
        <p>The GDST have appointed external pensions specialists Lane Clark & Peacock (LCP). They will be facilitating a number of live “Ask the pensions expert” sessions with teachers.  Details about these sessions will be provided in due course.</p>
        <p>Further details around the benefits payable from the TPS can be found <a href="https://www.teacherspensions.co.uk/members/member-hub.aspx" target="_blank">here</a>.</p>
        <p>Further details regarding the GDST Flexible Pension Plan can be found <a href="https://workplace.aviva.co.uk/gdst-flexible-pension-plan/" target="_blank">here</a>.</p>`,
      },
      {
        question: `Where can I get more information from other sources and about pensions more generally?`,
        answer: `<p><strong>Pensions Advisory Service (TPAS)</strong></p>
        <p>TPAS provides free and impartial advice and can be contacted via their website at <a href="www.pensionsadvisoryservice.org.uk" target="_blank">www.pensionsadvisoryservice.org.uk</a></p>
        <p><strong>The Pensions Regulator</strong></p>
        <p>The Pensions Regulator is the regulator of UK work-based pension schemes. You can find out more at <a href="www.thepensionsregulator.gov.uk" target="_blank">www.thepensionsregulator.gov.uk</a></p>
        <p><strong>The Pensions Ombudsman</strong></p>
        <p>The Pensions Ombudsman is appointed by the Secretary of State for Work and Pensions. The role of the Pensions Ombudsman is to investigate complaints about how pension schemes are run and to provide an impartial process to resolve these complaints. Their service is free and open to members who have a complaint against those responsible for the running or administration of pension schemes. You can find out more at their website <a href="www.pensions-ombudsman.org.uk" target="_blank">www.pensions-ombudsman.org.uk</a></p>
        <p><strong>The Money Advice Service</strong></p>
        <p>The Money Advice Service, set up by the government, provides free and impartial money advice. You can find out more at <a href="www.moneyadviceservice.org.uk" target="_blank">www.moneyadviceservice.org.uk</a></p>
        <p><strong>Pension Wise</strong></p>
        <p>Pension Wise is a free and impartial government service which offers guidance on defined contribution pension options. You can find out more at <a href="www.pensionwise.gov.uk" target="_blank">www.pensionwise.gov.uk</a></p>
        `,
      },
    ],
  },
];
