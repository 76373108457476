













import { Component, Vue } from 'vue-property-decorator';

import DocumentBox from '@/components/UI/DocumentBox.vue';

import documentsData from '@/documentsData';
import documentsData2202 from '@/documentsData2202';

@Component({
  components: {
    DocumentBox,
  },
})
export default class Information extends Vue {
  get getCurrentPage(): string {
    return this.$route.name;
  }

  public get documents() {
    return this.getCurrentPage  === 'LandingPage2202'? documentsData2202 :  documentsData;
  }
}
