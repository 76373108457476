




















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Slider from './../input/slider/Slider.vue';
import FormInput from './../input/form/FormInput.vue';
import FormInputSlider from './../input/form/FormInputSlider.vue';
import RadioInput from './../input/radio/RadioInput.vue';

@Component({
  components: { Slider, FormInput, RadioInput, FormInputSlider },
})
export default class OptionPanel extends Vue {
  @Prop() readonly data!: object;

  updateValue(key: string, value: any) {
    if (key === 'rewardOption') {
      this.$emit('update-parent-value', key, value);

      switch (value) {
        case 'Pay Max':
          key = 'memberDCcontribution';
          value = 2;
          break;
        case 'Pay Plus':
          key = 'memberDCcontribution';
          value = 5;
          break;
        case 'Pension Plus':
          key = 'memberDCcontribution';
          value = 8;
          break;
        case 'Pension Max':
          key = 'memberDCcontribution';
          value = 10;
          break;
      }
    }
    this.$emit('update-parent-value', key, value);
  }
}
