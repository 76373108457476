

























import { Vue, Component, Prop } from 'vue-property-decorator';
import PrettyRadio from 'pretty-checkbox-vue/radio';
import { closeCookie, cookie } from '../observables/ui';

@Component({
  components: {
    PrettyRadio,
  },
})
export default class CookieBar extends Vue {
  @Prop() readonly allowtracking: boolean;
  radioOptions = ['Allow all cookies', 'Allow only functional cookies'];
  checkedOption: any = 'Allow only functional cookies';

  created() {
    if (this.allowtracking) {
      this.checkedOption = 'Allow all cookies';
    }
  }

  updateRadioValue() {
    if (this.checkedOption === 'Allow only functional cookies') {
      this.$emit('updateTracking', false);
    } else {
      this.$emit('updateTracking', true);
    }
  }

  hideCookieBar() {
    this.updateRadioValue();
    closeCookie();
  }

  get showCookieBar() {
    return cookie.show;
  }
}
