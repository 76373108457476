var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "impact" }, [
      _c("div", { staticClass: "header" }, [_vm._v(" Impact on your salary ")]),
      _vm.illustration.retireInConsultation
        ? _c("div", [
            _vm._v(
              " Based on your chosen illustration you are due to retire before the implementation date. "
            )
          ])
        : _c("div", [
            _vm._v(
              " Under the proposals your monthly pension contribution would " +
                _vm._s(
                  _vm.illustration.inServiceImpact.memberContribution >
                    _vm.illustration.inServiceImpact.memberContributionTPS
                    ? "increase"
                    : "decrease"
                ) +
                " from approximately £" +
                _vm._s(
                  _vm._f("numeral")(
                    _vm.illustration.inServiceImpact.memberContributionTPS,
                    "0,0"
                  )
                ) +
                " to £" +
                _vm._s(
                  _vm._f("numeral")(
                    _vm.illustration.inServiceImpact.memberContribution,
                    "0,0"
                  )
                ) +
                " (and be made through Salary Sacrifice). "
            ),
            _vm.illustration.inServiceImpact.illustratedCashSuppliment > 0
              ? _c("span", [
                  _vm._v(
                    "With your reward option selected as '" +
                      _vm._s(_vm.options.rewardOption) +
                      "' your salary would be supplemented by £" +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm.illustration.inServiceImpact
                            .illustratedCashSuppliment,
                          "0,0"
                        )
                      ) +
                      " per month (before tax)."
                  )
                ])
              : _vm._e(),
            _vm._v(" The overall impact would be an approximate "),
            _c("span", { staticClass: "highlight" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.illustration.inServiceImpact.approxTakehomePay >
                      _vm.illustration.inServiceImpact.approxTakehomePayTPS
                      ? "increase"
                      : "decrease"
                  ) +
                  " in your take home pay of £" +
                  _vm._s(
                    _vm._f("numeral")(
                      Math.round(
                        Math.abs(
                          _vm.illustration.inServiceImpact.approxTakehomePay -
                            _vm.illustration.inServiceImpact
                              .approxTakehomePayTPS
                        ) / 10
                      ) * 10,
                      "0,0"
                    )
                  ) +
                  " per month"
              )
            ]),
            _vm._v(". ")
          ])
    ]),
    _c("div", { staticClass: "atretirement" }, [
      _c("div", { staticClass: "header" }, [_vm._v(" Retirement lump sum ")]),
      _vm._v(
        " Under the proposals, and based on your illustration options, you would receive a retirement lump sum (at age " +
          _vm._s(_vm.options.retirementAge) +
          ") of approximately £" +
          _vm._s(
            _vm._f("numeral")(
              Math.round(_vm.illustration.pensions.illustratedLumpSum / 100) *
                100,
              "0,0"
            )
          ) +
          ". This compares with a retirement lump sum of approximately £" +
          _vm._s(
            _vm._f("numeral")(
              Math.round(
                _vm.illustration.pensions.illustratedLumpSumTPS / 100
              ) * 100,
              "0,0"
            )
          ) +
          " if you were to remain in the TPS. "
      )
    ]),
    _c(
      "div",
      { staticClass: "charting" },
      [
        _c("div", { staticClass: "header" }, [
          _vm._v(" Retirement cashflow illustration ")
        ]),
        _c("chart", {
          key: "cashflowchart",
          attrs: { options: _vm.retirementIncomeChart }
        }),
        _vm.illustration.pensions.remainingDCPot > 0 &&
        _vm.options.illustrateDC == "Drawdown"
          ? _c("div", { staticClass: "remainingpot" }, [
              _vm._v(
                " You have chosen options that mean you have funds remaining in your Flexible Pension Plan of approximately £" +
                  _vm._s(
                    _vm._f("numeral")(
                      Math.round(
                        _vm.illustration.pensions.remainingDCPot / 100
                      ) * 100,
                      "0,0"
                    )
                  ) +
                  " at age 100. "
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }