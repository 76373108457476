














































import { Vue, Component } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import NavBar2202 from '@/components/NavBar2202.vue';
import FooterBar from '@/components/FooterBar.vue';
import CookieBar from '@/components/CookieBar.vue';
import TermsAndConditionsWording from '@/components/UI/TermsAndConditionsWording.vue';
import Login from '@/components/UI/Login.vue';
import dayjs from 'dayjs';
import Auth from './observables/Auth';
import PasswordAccess from '@/components/UI/PasswordAccess.vue';
import { terms, closeTerms, showCookie, cookie } from './observables/ui';
import VueGtag, { bootstrap } from 'vue-gtag';

Vue.use(VueGtag, {
  config: {
    id: `${process.env.VUE_APP_GA}`,
    params: {
      anonymize_ip: true,
    },
  },
  bootstrap: false,
});

@Component({
  components: {
    NavBar,
    NavBar2202,
    FooterBar,
    TermsAndConditionsWording,
    Login,
    PasswordAccess,
    CookieBar,
  },
})
export default class App extends Vue {
  public hack = 0;
  public allowtracking = false;

  isSecondaryLoggedIn = false;

  created() {
    const allowCookies = localStorage.getItem('allowCookies');
    if (allowCookies == 'true') {
      this.allowtracking = true;
      bootstrap().then(() => {
        // all done!
      });
    } else {
      if (allowCookies == null) {
        this.allowtracking = true;
      } else {
        this.allowtracking = false;
      }
    }
  }

  get getCurrentPage(): string {
    return this.$route.name;
  }

  updateTracking(tracking) {
    localStorage.setItem('allowCookies', tracking);
    this.allowtracking = tracking;
    if (this.isTCAgreed && this.allowtracking) {
      bootstrap().then(() => {
        // all done!
        this.$gtag.optIn();
      });
    } else if (this.isTCAgreed && !this.allowtracking) {
      this.$gtag.optOut();
    }
  }

  closeTermsAndConditions() {
    closeTerms();
  }

  acceptTerms() {
    const agreementTimestamp: number = dayjs()
      .add(1, 'month')
      .unix();
    window.localStorage.setItem('GDSTTerms', agreementTimestamp.toString());
    showCookie();
    this.hack++;
  }

  get isShowTerms() {
    return terms.show;
  }
  get loggedIn() {
    //return true;
    return Auth.state.loggedIn;
  }

  get user() {
    return Auth.state.userInfo;
  }

  get showCookieBar() {
    return cookie.show;
  }

  get isTCAgreed() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const hack = this.hack;
    const localTermsAgreed = localStorage.getItem('GDSTTerms');
    let tcValid = false;
    if (localTermsAgreed) {
      if (parseInt(localTermsAgreed) > dayjs().unix()) {
        tcValid = true;
      }
    }
    return tcValid;
  }

  userAccess(passwordValue) {
    if (passwordValue) {
      window.localStorage.setItem('user', 'user');
      this.isSecondaryLoggedIn = true;
    }
  }
}
