var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selection" }, [
    _vm._m(0),
    _c("div", { staticClass: "options" }, [
      _c("div", { staticClass: "select-box feedback" }, [
        _c(
          "div",
          { staticClass: "feedback-inner" },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fas", "comment-alt-lines"] }
            }),
            _c(
              "a",
              {
                attrs: {
                  href: "https://forms.gle/QFo87xFGMvU4AdNf7",
                  target: "_blank"
                }
              },
              [_vm._v("Feedback")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "select-box question" }, [
        _c(
          "div",
          { staticClass: "question-inner flex-align-center" },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["far", "question-circle"] }
            }),
            _c(
              "a",
              {
                attrs: {
                  href: "https://forms.gle/vPYtmSe4gCYRSYrn9",
                  target: "_blank"
                }
              },
              [_vm._v("Question")]
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "sub-comment" }, [
      _vm._v(
        " Clicking these links will take you to forms away from the consultation hub. "
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feedback-question-header" }, [
      _c("h3", { staticClass: "section-heading " }, [
        _vm._v(" Please select from below if you have a question or feedback ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }