var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video--container" }, [
    _c(
      "div",
      {
        staticClass: "closeVideo",
        on: {
          click: function($event) {
            return _vm.$emit("closeModal")
          }
        }
      },
      [_c("font-awesome-icon", { attrs: { icon: ["fal", "times"] } })],
      1
    ),
    _c("div", { staticClass: "inner--content" }, [
      _c("ul", { staticClass: "inner--content-tabs" }),
      _c("div", { staticClass: "placeholder--container" }, [
        _c("ul", { staticClass: "inner--content-placeholder" }, [
          _vm.activeTab === "video"
            ? _c("li", [
                _vm.videoToShow === "introVideo"
                  ? _c("div", { staticClass: "video intro" }, [
                      _c(
                        "video",
                        {
                          attrs: {
                            controls: "",
                            autoplay: "",
                            width: "750",
                            height: _vm.videoHeight
                          }
                        },
                        [
                          _c("source", {
                            attrs: { src: _vm.video, type: "video/mp4" }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.videoToShow !== "introVideo"
                  ? _c("div", { staticClass: "video" }, [
                      _c(
                        "video",
                        {
                          attrs: {
                            controls: "",
                            autoplay: "",
                            width: "750",
                            height: _vm.videoHeight
                          }
                        },
                        [
                          _c("source", {
                            attrs: { src: _vm.videoToShow, type: "video/mp4" }
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.activeTab === "transcript"
            ? _c("li", { staticClass: "transcript" }, [_vm._v("Trans")])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }