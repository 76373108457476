






















import { Component, Vue, Prop } from 'vue-property-decorator';

import vSlider from 'vue-slider-component';
import '../../../assets/scss/slider/customSlider.scss';

@Component({
  components: { vSlider },
})
export default class FormInput extends Vue {
  @Prop() readonly formLabel!: string;

  isActive = false;
  isInFocus = false;
}
