















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import moment from 'moment';

const isDate = function(val) {
  if (val.length != 10) return false;
  const dateval = moment(val, 'DD/MM/YYYY');
  return dateval.isValid();
};

const isBetween = function(val) {
  if (isDate(val)) {
    const isB = moment(val, 'DD/MM/YYYY').isBetween(moment(this.minDate), moment(this.maxDate));
    return isB;
  }
  return false;
};

//here
@Component({})
export default class FormInputDate extends Vue {
  @Prop() readonly formLabel!: string;
  @Prop() readonly helpText!: string;
  @Prop() readonly dataKey!: string;
  @Prop() readonly dataValue!: any;
  @Prop() readonly dirtyInput!: boolean;
  @Prop() readonly minDate!: Date;
  @Prop() readonly maxDate!: Date;

  isActive = false;
  isInFocus = false;
  inFocus = false;
  texting = '';
  formValue = '';

  @Validations()
  validations = {
    formValue: { required, isDate, isBetween },
  };

  get formattedValueStyle() {
    if ((!this.$v.formValue.isDate || !this.$v.formValue.isBetween) && this.$v.$dirty) {
      return 'formatted-value error';
    }
    return 'formatted-value';
  }

  hideFormatting() {
    this.inFocus = true;
    (this.$refs.inputValue as HTMLBaseElement).focus();
  }
  updateFormValue(e) {
    this.formValue = e.target.value;
    this.$v.formValue.$touch();
    this.$emit('update-parent-value', this.dataKey, this.formValue);
    this.$emit('update-parent-error', this.dataKey, this.$v.$anyError);
  }

  get formatValue() {
    return this.formValue;
  }

  created() {
    this.formValue = this.dataValue;
    this.$emit('update-parent-error', this.dataKey, !isDate(this.formValue));
  }

  @Watch('dirtyInput')
  makeInputsDirty(newDirty: boolean) {
    if (newDirty) {
      this.$v.formValue.$touch();
      this.$emit('update-parent-error', this.dataKey, this.$v.$anyError);
    }
  }
}
