














import { Component, Vue } from 'vue-property-decorator';
import Loading from './Loading.vue';
import Auth from '@/observables/Auth';
@Component({ components: { Loading } })
export default class LoadingComponent extends Vue {
  loading = true;

  async mounted() {
    const token = await Auth.getToken();
    if (!token)
      await this.login().finally(() => {
        this.loading = false;
      });
  }

  async login() {
    await Auth.login();
  }
}
