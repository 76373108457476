


































import { Component, Vue } from 'vue-property-decorator';
import faqList from '@/components/UI/FaqList.vue';
import Multiselect from 'vue-multiselect';
import { faqData } from '../../faqData';

@Component({
  components: {
    Multiselect,
    faqList,
  },
})
export default class QuestionList extends Vue {
  public questionValue = '';
  public questionFaqResults = [];

  public questionList = [
    {
      question: 'Why changes are being proposed',
      associatedFaqs: [
        { category: 'The consultation', faqIndex: 1 },
        {
          category: 'How the proposed changes would affect your benefits',
          faqIndex: 0,
        },
      ],
    },
    {
      question: 'General pension or pay query',
      associatedFaqs: [
        { category: 'The consultation', faqIndex: 1 },
        {
          category: 'How the proposed changes would affect your benefits',
          faqIndex: 3,
        },
      ],
    },
    {
      question: 'The TPS',
      associatedFaqs: [
        { category: 'The consultation', faqIndex: 1 },
        {
          category: 'How the proposed changes would affect your benefits',
          faqIndex: 3,
        },
      ],
    },
    {
      question: 'The GDST Flexible Pension Plan',
      associatedFaqs: [
        { category: 'The consultation', faqIndex: 1 },
        {
          category: 'How the proposed changes would affect your benefits',
          faqIndex: 0,
        },
      ],
    },
    {
      question: 'The four pension and pay options',
      associatedFaqs: [
        { category: 'The consultation', faqIndex: 1 },
        {
          category: 'How the proposed changes would affect your benefits',
          faqIndex: 2,
        },
      ],
    },
    {
      question: 'The modeller on the consultation hub',
      associatedFaqs: [
        { category: 'The consultation', faqIndex: 1 },
        {
          category: 'How the proposed changes would affect your benefits',
          faqIndex: 3,
        },
      ],
    },
    { question: 'Something else?', associatedFaqs: null },
  ];

  public displayForm() {
    this.$emit('showFeedback', true);
  }

  public checkForSomethingElse(dropdownValue): void {
    this.$emit('setQuestionValue', dropdownValue.question);
    if (dropdownValue.question === 'Something else?') {
      this.$emit('showFeedback', true);
    }
  }

  get findFaq(): any {
    const questionsFaqList = [];

    if ((this.questionValue as any).associatedFaqs) {
      const filteredQuestions = this.questionList.filter(
        (question) => question.question === (this.questionValue as any).question,
      );
      filteredQuestions.forEach((faqQuestion) => {
        faqQuestion.associatedFaqs.forEach((questionCat) => {
          faqData.filter((faqList) => {
            if (questionCat.category === faqList.category)
              questionsFaqList.push(faqList.faqs[questionCat.faqIndex]);
          });
        });
      });
    }
    return questionsFaqList;
  }
}
