var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "feedback-question",
      class: { "feedback-form": _vm.currentView === "feedbackForm" }
    },
    [
      _c(
        "div",
        {
          staticClass: "close flex-align-center",
          on: {
            click: function($event) {
              return _vm.$emit("closeFeedbackModal")
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fal", "times"] } })],
        1
      ),
      _c(
        "div",
        { staticClass: "feedback-question--inner" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", appear: "" } },
            [
              _vm.currentView === "questionOrFeedback"
                ? _c("UserSelection", {
                    key: "selection",
                    on: {
                      showQuestionListOrFeedback: _vm.showQuestionListOrFeedback
                    }
                  })
                : _vm._e(),
              _vm.currentView === "feedbackForm"
                ? _c("FeedbackForm", {
                    attrs: {
                      questionValue: _vm.selection.question,
                      path: _vm.selection.path
                    }
                  })
                : _vm._e(),
              _vm.currentView === "questionList"
                ? _c("QuestionList", {
                    key: "question-list",
                    on: {
                      showFeedback: _vm.showFeedback,
                      setQuestionValue: _vm.setQuestionValue
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }