var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-text-wrapper" }, [
      _c("h4", { staticClass: "with-underline" }, [
        _vm._v(
          " What to do if you cannot access TPS My Pension Online or your benefit statement "
        )
      ]),
      _c("h4", [_vm._v("TPS My Pension Online")]),
      _c("p", [
        _vm._v(
          " If you haven’t already done so, you will need to register for the TPS digital service, called “My Pension Online”. You will need your National Insurance Number and ideally a personal email address. You can register "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://www.teacherspensions.co.uk/forms/registration.aspx",
              target: "_blank"
            }
          },
          [_vm._v("here")]
        ),
        _vm._v(". ")
      ]),
      _c("p", [
        _vm._v(
          " Once you have access you will be able to access all the information relating to your TPS pension. "
        )
      ]),
      _c("h4", [
        _vm._v("Forgotten TPS password or email address you’ve registered with")
      ]),
      _c("p", [
        _vm._v(
          " You can get a reminder of your email address and password on the "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://www.teacherspensions.co.uk/members/member-login.aspx",
              target: "_blank"
            }
          },
          [_vm._v("TPS website")]
        ),
        _vm._v(". ")
      ]),
      _c("h4", { staticClass: "with-underline" }, [
        _vm._v(
          " What to do if you have a question about your TPS benefit statement "
        )
      ]),
      _c("p", [
        _c("strong", [
          _vm._v(
            "If you don’t understand the information on your benefit statement: "
          )
        ]),
        _vm._v(
          "from your TPS My Pension Online log-on you can send a secure message to the team at TPS or call them on 0345 606 6166 (you will need your NI number and TPS reference number for security purposes). "
        )
      ]),
      _c("p", [
        _c("strong", [
          _vm._v(
            "If your service or salary is not correct for a previous employer/school: "
          )
        ]),
        _vm._v(
          "you will need to contact your previous employer to raise the query as GDST will not have access to this (most likely to be the HR or Payroll department at your previous employer). "
        )
      ]),
      _c("p", [
        _c("strong", [
          _vm._v("If your GDST service or salary is not correct:")
        ]),
        _vm._v(" please send an email to "),
        _c("strong", [_vm._v("p.department@wes.gdst.net")]),
        _vm._v(" with your query and any supporting documentation. ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }