


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class IntroDefaultHolderDownload extends Vue {
  @Prop() document!: any;

  scrollToSection(classN: string) {
    const el = document.querySelector(classN) as HTMLDivElement;
    el.scrollIntoView();
    this.$emit('scrollTo', classN);
  }
}
