

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
  components: {
    Chart,
  },
})
export default class OutputPanel extends Vue {
  @Prop() readonly member: any;
  @Prop() readonly options: any;
  @Prop() readonly illustration: any;

  get retirementIncomeChart() {
    const cats = [];

    const retAge = parseInt(this.options.retirementAge);

    for (let a = retAge; a < 100; a += 1) cats.push(a);

    const series = [];

    series.push({
      name: 'Pension from the GDST Flexible Pension Plan',
      data: this.illustration.pensions.illustratedDCincome
        .slice(0, 100 - this.options.retirementAge)
        .map((d) => Math.round(d / 100) * 100),
      color: 'rgb(167,188,214)',
      pointPadding: 0,
    });

    series.push({
      name: 'Pension from deferred TPS benefts',
      data: this.illustration.pensions.totalDeferredTPSbenefits
        .slice(0, 100 - this.options.retirementAge)
        .map((d) => Math.round(d / 100) * 100),
      color: 'rgb(210,91,115)',
      pointPadding: 0,
    });

    series.push({
      name: 'State pension',
      data: this.illustration.pensions.statePension
        .slice(0, 100 - this.options.retirementAge)
        .map((d) => Math.round(d / 100) * 100),
      color: 'rgb(239,190,125)',
      pointPadding: 0,
    });

    series.push({
      name: 'TPS benefits and state pension',
      data: this.illustration.pensions.theoreticalFutureTPSbenefitsIncStatePension
        .slice(0, 100 - this.options.retirementAge)
        .map((d) => Math.round(d / 100) * 100),
      color: 'rgb(0,0,0)',
      type: 'line',
      dashStyle: 'Dash',
    });

    // total expenditure

    const chartOptions = {
      chart: {
        type: 'column',
        marginLeft: 80,
        marginRight: 20,
        marginBottom: 100,
        backgroundColor: 'transparent',
        height: 400,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: cats,
      },
      yAxis: {
        title: {
          text: 'Gross pension income (£)',
        },
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        borderColor: '#CCC',
        backgroundColor: 'white',
        borderWidth: 1,
        shadow: false,
        itemStyle: {
          fontSize: '12px',
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        backgroundColor: 'white',
        // useHTML: true,
        // formatter () {
        //     // see if income is enought
        //     if (this.points[0].y > this.points[2].y) {
        //         return `<table>
        //                 <tr>
        //                     <td style='padding: 5px;'>Age</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${this.x}</b></td>
        //                 </tr>
        //                 <tr>
        //                     <td style='padding: 5px;'>Takehome income</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${(Math.round((this.points[0].y) / 100) / 10).toFixed(1)}k pa</b></td>
        //                 </tr>
        //                 <tr>
        //                     <td style='padding: 5px;'>Total expenditure</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${(Math.round((this.points[2].y) / 100) / 10).toFixed(1)}k pa</b></td>
        //                 </tr>
        //                 <tr style='color: darkgreen; font-weight: bold;'>
        //                     <td style='padding: 5px;'>Surplus income</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${(Math.round((this.points[0].y - this.points[2].y) / 100) / 10).toFixed(1)}k pa</b></td>
        //                 </tr>
        //             </table>`;
        //     }
        //     return `<table>
        //                 <tr>
        //                     <td style='padding: 5px;'>Age</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${this.x}</b></td>
        //                 </tr>
        //                 <tr>
        //                     <td style='padding: 5px;'>Takehome income</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${(Math.round((this.points[0].y) / 100) / 10).toFixed(1)}k pa</b></td>
        //                 </tr>
        //                 <tr>
        //                     <td style='padding: 5px;'>Total expenditure</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${(Math.round((this.points[2].y) / 100) / 10).toFixed(1)}k pa</b></td>
        //                 </tr>
        //                 <tr style='color: red; font-weight: bold;'>
        //                     <td style='padding: 5px;'>Income shortfall</td>
        //                     <td style='padding: 5px; text-alight: right;'><b>${(Math.round((this.points[2].y - this.points[0].y) / 100) / 10).toFixed(1)}k pa</b></td>
        //                 </tr>
        //             </table>`;
        // }, // ,
        headerFormat: '<b>Age {point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y:,.-0f}<br/>',
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
        line: {
          lineWidth: 3,
          marker: {
            enabled: false,
          },
        },
      },
      series,
    };

    return chartOptions;
  }
}
