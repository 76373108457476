import AuthenticationService from '@/auth/authenticationService';
import Vue from 'vue';
import { AuthenticationResult, Configuration } from '@azure/msal-browser';

let authenticationService: AuthenticationService | null = null;

interface AuthStore {
  token: string;
  loggedIn: boolean;
  userInfo: unknown;
}

const authState: AuthStore = Vue.observable({
  token: '',
  loggedIn: false,
  userInfo: null,
});

const initialise = () => {
  const authority = process.env.VUE_APP_AUTH_AUTHORITY;
  const config: Configuration = {
    auth: {
      clientId: process.env.VUE_APP_AUTH_CLIENTID,
      authority: authority,
      knownAuthorities: [`https://${authority?.replace('https://', '').split('/')[0]}`],
      redirectUri: window.location.origin,
    },
  };
  const scopes = {
    scopes: ['openid'],
  };
  authenticationService = new AuthenticationService(config, scopes);
};

export default {
  state: authState,
  login() {
    if (!authenticationService) initialise();
    return authenticationService.login();
  },
  logout() {
    if (!authenticationService) initialise();
    return authenticationService.logout();
  },
  async getToken() {
    if (!authenticationService) initialise();
    const token = (await authenticationService.getToken()) as AuthenticationResult | null;
    if (token) {
      authState.loggedIn = !!token.accessToken;
      authState.userInfo = token.account?.idTokenClaims || token.idTokenClaims;
    }
    return authState.loggedIn;
  },
};
