var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" }, attrs: { id: "app" } },
    [
      !_vm.loggedIn
        ? _c("login")
        : !_vm.isTCAgreed && _vm.loggedIn
        ? _c("base-modal", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "access" },
                      [
                        _c("TermsAndConditionsWording"),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--standard btn--equal",
                            on: { click: _vm.acceptTerms }
                          },
                          [_vm._v(" I accept terms & conditions ")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _c(
            "div",
            [
              _vm.isShowTerms
                ? _c("base-modal", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "access" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "closeVideo",
                                      on: { click: _vm.closeTermsAndConditions }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fal", "times"] }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("TermsAndConditionsWording")
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      643283377
                    )
                  })
                : _vm._e(),
              _vm.getCurrentPage === "LandingPage"
                ? _c(
                    "router-link",
                    {
                      staticClass: "legacy-badge",
                      attrs: {
                        to: "/",
                        title:
                          "The is a legacy version of the website and is no longer supported. Click to view the latest version"
                      }
                    },
                    [
                      _vm._v(" Legacy "),
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "exclamation-triangle"] }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.getCurrentPage === "LandingPage"
                ? _c(
                    "router-link",
                    { staticClass: "fixed legacy-banner", attrs: { to: "/" } },
                    [
                      _vm._v(
                        " This is a legacy version of the website and is no longer supported. Click this banner to view the latest version "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.getCurrentPage === "LandingPage2202"
                ? _c("NavBar2202")
                : _c("NavBar"),
              _c("router-view"),
              _c("FooterBar"),
              _c("CookieBar", {
                attrs: { allowtracking: _vm.allowtracking },
                on: { updateTracking: _vm.updateTracking }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }