var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "indicate--section-arrow" },
    [
      _c("font-awesome-icon", {
        staticClass: "chevron",
        attrs: { icon: ["far", "chevron-double-down"] }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }