






























































































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator';

import baseCard from '@/components/baseCard/baseCard.vue';
import modellerSection2202 from '@/components/ModellerSection2202.vue';
import understandChanges from '@/components/UnderstandChanges.vue';
import feedbackIcon from '@/components/UI/FeedbackIcon.vue';
import feedbackForm from '@/components/input/form/FeedbackForm.vue';
import FaqsFiltered from '@/components/UI/FaqsFiltered.vue';
import ScrollToSection from '@/components/UI/ScrollToSection.vue';
import Information from '@/components/UI/Information.vue';
import Video from '@/components/UI/Video.vue';
import QuestionFeedbackWrapper from '@/components/UI/QuestionFeedbackWrapper.vue';
import FeedbackOrQuestion from '@/components/FeedbackOrQuestion.vue';
import IntroVideoHolder from '@/components/UI/IntroVideoHolder.vue';
import IntroDefaultHolderDownload from '@/components/UI/IntroDefaultHolderDownload.vue';
import { VueAgile } from 'vue-agile';

import { questionOrFeedback } from '@/observables/ui';

@Component({
  components: {
    baseCard,
    modellerSection2202,
    understandChanges,
    FaqsFiltered,
    feedbackForm,
    feedbackIcon,
    ScrollToSection,
    Information,
    Video,
    FeedbackOrQuestion,
    QuestionFeedbackWrapper,
    agile: VueAgile,
    IntroVideoHolder,
    IntroDefaultHolderDownload,
  },
})
export default class LandingPage extends Vue {
  public showModal = false;
  public showIntroVideo = false;
  public videoToShow = {
    type: '',
    video: '',
  };
  public sliderIndex = 0;

  public introSliderContent = [
    {
      type: 'download',
      title: 'Q&A',
      subText: `Questions and Answers updated on 20 May 2022`,
      tab: 'qna',
      downloadLink: `/docs/TPS Q&A Pensions - 20th May Update.pdf`,
      downloadDocName: `TPS Q&A Pensions - 20th May Update.pdf`,
      imageLink: `/img/2022-03-02QnA.PNG`,
    },
    {
      type: 'download',
      title: 'Tool guide',
      subText: `A guide on how to use the GDST Illustration Tool`,
      tab: 'howto',
      downloadLink: `/docs/2022 04 - GDST - Illustration tool how to v2.pdf`,
      downloadDocName: `2022 04 - GDST - Illustration tool how to v2.pdf`,
      imageLink: `/img/GDST - Illustration tool how to.PNG`,
    },
    {
      type: 'download',
      title: 'Checklist',
      subText: `Use this checklist to help you with your choice. Added 30 March.`,
      tab: 'checklist',
      downloadLink: `/docs/TPS - Choosing your pension option.pdf`,
      downloadDocName: `TPS - Choosing your pension option.pdf`,
      imageLink: `/img/choosing-your-pension-option.PNG`,
    },
    {
      type: 'download',
      title: 'Offer Summary',
      subText: `A summary of the full and final offer sent on 11 March 2022`,
      tab: 'mythbuster',
      downloadLink: `/docs/TPS-Summary-of-proposal.pdf`,
      downloadDocName: `TPS-Summary-of-proposal.pdf`,
      imageLink: `/img/summary-proposal.png`,
    },
    {
      type: 'download',
      title: 'Factsheet 1',
      subText: 'The proposed GDST Flexible Pension Plan - key features. Updated on 11 March 2022',
      downloadLink: '/docs/GDST_TPS_FactSheet1.pdf',
      downloadDocName: 'GDST_TPS_FactSheet1.pdf',
      imageLink: `/img/factsheet1-2022.png`,
    },
    {
      type: 'download',
      title: 'FPP Booklet',
      subText: 'The GDST Flexible Pension Plan Booklet',
      downloadLink: '/docs/2022-03 The GDST Flexible Pension Plan for offer letter.pdf',
      downloadDocName: '2022-03 The GDST Flexible Pension Plan for offer letter.pdf',
      imageLink: `/img/offer-letter.png`,
    },
  ];

  updateCarousel(index: number) {
    this.sliderIndex = index;
    const carousel = this.$refs.videoCarousel as any;

    carousel.goTo(index);
  }
  updateSliderIndex(data: any) {
    this.sliderIndex = data.currentSlide;
  }
  showFeedbackModal() {
    questionOrFeedback.showFeedbackOrQuestion = true;
  }

  closeFeedbackModal() {
    questionOrFeedback.showFeedbackOrQuestion = false;
  }

  closeModal() {
    this.showModal = false;
    this.showIntroVideo = false;
  }
  scrollToSection(classN: string) {
    const el = document.querySelector(classN) as HTMLDivElement;
    el.scrollIntoView();
    this.$emit('scrollTo', classN);
  }

  // public handleShowVideo(type: string, index: number): void {
  //   this.videoToShow = {
  //     type,
  //     video: this.introSliderContent[index].video,
  //   };
  //   this.showIntroVideo = true;
  //   const pageV = `video - intro`;
  //   this.$gtag.screenview({
  //     app_name: 'hub',
  //     screen_name: pageV,
  //   });
  // }

  closeIntroVideo() {
    this.showIntroVideo = false;
  }

  get isFeedbackOuestionModal() {
    return questionOrFeedback.showFeedbackOrQuestion;
  }
}
