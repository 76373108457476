











































































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class ModellerAssumption extends Vue {
  @Prop() readonly pen80: boolean;

  data() {
    return {};
  }
}
