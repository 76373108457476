var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feedback-svg",
      staticStyle: { "enable-background": "new 0 0 512 512" },
      attrs: {
        version: "1.1",
        id: "Capa_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 512 512",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("g", [
          _c("g", [
            _c("path", {
              attrs: {
                d:
                  "M462.75,210.479c-3.979-1.656-8.583-0.74-11.625,2.313l-65.854,65.833c-4.479,4.49-10.146,7.51-16.333,8.75\n\t\t\t\tl-53.333,10.667c-10.625,2.146-21.375-1.229-28.896-8.74c-7.583-7.594-10.854-18.406-8.75-28.906l9.408-47.063H117.333\n\t\t\t\tc-5.896,0-10.667-4.771-10.667-10.667c0-5.896,4.771-10.667,10.667-10.667h179.061c0.371-0.411,0.587-0.928,0.98-1.323\n\t\t\t\tl41.35-41.344H117.333c-5.896,0-10.667-4.771-10.667-10.667c0-5.896,4.771-10.667,10.667-10.667H352\n\t\t\t\tc2.271,0,4.259,0.866,5.988,2.074l69.22-69.21c5.833-5.844,12.896-10.438,21-13.667c3.688-1.469,6.25-4.875,6.646-8.823\n\t\t\t\tc0.417-3.958-1.417-7.802-4.729-9.99c-7.021-4.615-15.125-7.052-23.458-7.052h-384C19.146,21.333,0,40.469,0,64v416\n\t\t\t\tc0,4.313,2.604,8.208,6.583,9.854c1.313,0.552,2.708,0.813,4.083,0.813c2.771,0,5.5-1.083,7.542-3.125L121.75,384h304.917\n\t\t\t\tc23.521,0,42.667-19.135,42.667-42.667v-121C469.333,216.021,466.729,212.125,462.75,210.479z M245.333,277.333h-128\n\t\t\t\tc-5.896,0-10.667-4.771-10.667-10.667c0-5.896,4.771-10.667,10.667-10.667h128c5.896,0,10.667,4.771,10.667,10.667\n\t\t\t\tC256,272.563,251.229,277.333,245.333,277.333z"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M471.167,64c-10.771,0-21.292,4.365-28.875,11.958L312.458,205.771c-1.5,1.49-2.5,3.385-2.917,5.448l-10.667,53.354\n\t\t\t\tc-0.708,3.5,0.396,7.115,2.917,9.635c2.021,2.021,4.75,3.125,7.542,3.125c0.688,0,1.396-0.073,2.083-0.208l53.313-10.667\n\t\t\t\tc2.083-0.417,3.979-1.427,5.458-2.917l129.854-129.833c0,0,0,0,0-0.01c7.604-7.604,11.958-18.125,11.958-28.865\n\t\t\t\tC512,82.313,493.688,64,471.167,64z M454.249,149.332l-27.587-27.587l15.083-15.083l27.589,27.589L454.249,149.332z"
              }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }