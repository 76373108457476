















import { Component, Vue } from 'vue-property-decorator';
@Component
export default class PasswordAccess extends Vue {
  public passwordValue = '';

  giveUserAccess() {
    if (this.passwordValue === '#change2022') {
      this.$emit('userAccess', this.passwordValue);
    } else {
      return;
    }
  }
}
