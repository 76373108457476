var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "password-access" }, [
    _c("div", { staticClass: "password-access-wording" }, [
      _c("p", [_vm._v("Demo site enter password to access.")]),
      _c("div", { staticClass: "password-input" }, [
        _c("label", [_vm._v("Password:")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordValue,
              expression: "passwordValue"
            }
          ],
          attrs: { type: "password" },
          domProps: { value: _vm.passwordValue },
          on: {
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.giveUserAccess.apply(null, arguments)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.passwordValue = $event.target.value
            }
          }
        })
      ])
    ]),
    _c(
      "button",
      {
        staticClass: "btn btn--standard btn--equal",
        on: { click: _vm.giveUserAccess }
      },
      [_vm._v(" Access ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }