











// import dynamicTemplate from 'v-runtime-template';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { faqShowMore } from '../../observables/ui';

@Component({})
export default class FaqList extends Vue {
  @Prop() faq: any;
  @Prop(Boolean) openOverride: boolean;
  @Prop(String) catSearch: string;
  answerOpen = false;
  divHeight = 0;

  mounted() {
    this.getAnswerHeights();
  }

  get question() {
    return this.catSearch !== ''
      ? this.faq.question.replace(
          new RegExp(this.catSearch, 'gi'),
          (match) => `<span class="highlight">${match}</span>`,
        )
      : this.faq.question;
  }
  get answer() {
    return this.catSearch && this.catSearch.length > 1
      ? this.faq.answer.replace(
          new RegExp(this.catSearch, 'gi'),
          (match) => `<span class="highlight">${match}</span>`,
        )
      : this.faq.answer;
  }

  @Watch('openOverride', { immediate: false })
  showAnswer(show: boolean) {
    const answerDiv = this.$refs.answer as HTMLElement;
    if (show) {
      answerDiv.style.maxHeight = `${this.divHeight}px`;
    } else {
      answerDiv.style.maxHeight = `0px`;
    }
    this.answerOpen = show;
  }

  @Watch('catSearch', { immediate: false })
  resetHeights(catSearch: string) {
    if (catSearch === '') {
      const answerDiv = this.$refs.answer as HTMLElement;

      answerDiv.style.maxHeight = `0px`;
    }
  }

  getAnswerHeights() {
    const answerDiv = this.$refs.answer as HTMLElement;
    this.divHeight = answerDiv.clientHeight + 250;
    answerDiv.style.maxHeight = '0px';
  }

  toggleAnswer() {
    const answerDiv = this.$refs.answer as HTMLElement;
    if (this.answerOpen) {
      answerDiv.style.maxHeight = '0px';
    } else {
      answerDiv.style.maxHeight = `${this.divHeight}px`;
    }
    this.answerOpen = !this.answerOpen;
    faqShowMore();
  }
}
