















import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class BaseModal extends Vue {
  @Prop({ default: 'default' }) modalOverlay: string;
  closeModal() {
    this.$emit('closeModal');
  }
}
