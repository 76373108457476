var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "output-wrapper" }, [
    _c(
      "div",
      { staticClass: "inner-wrapper" },
      [
        _c("RadioInput", {
          attrs: {
            inputName: "Pay and pension option",
            radioOptions: [
              "Pay Max",
              "Pay Plus",
              "Pension Plus",
              "Pension Max"
            ],
            dataValue: _vm.data.rewardOption,
            dataKey: "rewardOption",
            tooltipData:
              "Please refer to the consultation or video guides to find out more about what the different reward options are."
          },
          on: { "update-parent-value": _vm.updateValue }
        }),
        _vm.data.rewardOption === "Pension Max"
          ? _c("Slider", {
              attrs: {
                inputName: "Your pension contributions",
                dataValue: _vm.data.memberDCcontribution,
                dataKey: "memberDCcontribution",
                unit: "percentage",
                minValue: 10,
                maxValue: 30,
                defaultValue: 10,
                tooltipData:
                  "To boost your savings, you could choose to pay more contributions into the GDST Flexible Pension Plan"
              },
              on: { "update-parent-value": _vm.updateValue }
            })
          : _vm._e(),
        _c("Slider", {
          attrs: {
            inputName:
              "Illustrated level of salary increases above inflation (%)",
            dataValue: _vm.data.salaryGrowth,
            dataKey: "salaryGrowth",
            unit: "percentage",
            minValue: 0,
            maxValue: 3,
            step: 0.5,
            defaultValue: 0,
            tooltipData:
              "This should reflect the average salary increases you might expect to get over the remainder of your working lifetime, relative to inflation.  If you’re at the top of your salary scale you might only expect to get increases in line with inflation going forwards, and so would select 0%."
          },
          on: { "update-parent-value": _vm.updateValue }
        }),
        _c("RadioInput", {
          attrs: {
            inputName:
              "Illustrated investment returns in the GDST Flexible Pension Plan",
            radioOptions: ["Low", "Medium", "High"],
            dataValue: _vm.data.investmentGrowth,
            dataKey: "investmentGrowth",
            tooltipData:
              "The investment returns you receive in a DC scheme aren’t guaranteed.  We’ve given you the ability to vary the assumed level of return, although in practice the actual investment returns could be higher or lower than any of the scenarios illustrated here.  Please see the assumptions for more detailed information."
          },
          on: { "update-parent-value": _vm.updateValue }
        }),
        _c("Slider", {
          attrs: {
            inputName: "Retirement age",
            dataValue: _vm.data.retirementAge,
            dataKey: "retirementAge",
            minValue: _vm.data.minRetirementAge,
            maxValue: _vm.data.maxRetirementAge,
            defaultValue: _vm.data.retirementAge,
            tooltipData:
              "This is the age you would like to start drawing all of your benefits from the TPS and/or GDST Flexible Pension Plan.  We’ve assumed you’d like to take all your benefits at once, but in practice you may be able to take different benefits at different ages."
          },
          on: { "update-parent-value": _vm.updateValue }
        }),
        _c("RadioInput", {
          attrs: {
            inputName: "Show maximum cash lump sum at retirement?",
            radioOptions: ["Yes", "No"],
            dataValue: _vm.data.maxTaxFreeLumpSum,
            dataKey: "maxTaxFreeLumpSum",
            tooltipData:
              "If you select 'Yes' we’ll illustrate the position were you to take the maximum tax-free cash lump sum at retirement possible.  If you select 'No' we’ll assume you take the minimum lump sum possible."
          },
          on: { "update-parent-value": _vm.updateValue }
        }),
        _c("RadioInput", {
          attrs: {
            inputName: "Retirement option in the GDST Flexible Pension Plan",
            radioOptions: ["Drawdown", "Annuity"],
            dataValue: _vm.data.illustrateDC,
            dataKey: "illustrateDC",
            tooltipData:
              "Please refer to the consultation guide or videos to find out more about what the different options available to you in retirement would be."
          },
          on: { "update-parent-value": _vm.updateValue }
        }),
        _vm.data.illustrateDC == "Drawdown"
          ? _c("FormInput", {
              attrs: {
                inputType: "pounds",
                dataKey: "targetRetirementIncome",
                formLabel:
                  "Use drawdown to target a level of income in retirement",
                minValue: 0,
                maxValue: 1000000,
                helpText:
                  "We’ll assume you draw the amount needed to meet your target from your GDST Flexible Pension Plan pot. This will continue as long as there are sufficient funds in your pot.",
                dataValue: _vm.data.targetRetirementIncome
              },
              on: { "update-parent-value": _vm.updateValue }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }