var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal--overlay",
        class: _vm.modalOverlay === "video" ? "white-bg" : "default-bg",
        on: { click: _vm.closeModal }
      },
      [
        _c(
          "div",
          { staticClass: "modal--content" },
          [_vm._t("title"), _vm._t("content")],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }