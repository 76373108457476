const documentsData = [
  {
    title: `Q&A update`,
    subText: `Issued on 24 January 2022`,
    downloadLink: `/docs/2022-01 q_and_a_update.pdf`,
    downloadDocName: `2022-01 q_and_a_update.pdf`,
    dateAdded: '2022-01-24',
    expireDays: 30,
  },
  {
    title: `Factsheet 1`,
    subText: `The proposed GDST Flexible Pension Plan – key features`,
    downloadLink: `/docs/2021-11 GDST_TPS_FactSheet_1.pdf`,
    downloadDocName: `2021-11 GDST_TPS_FactSheet_1.pdf`,
    dateAdded: '2021-11-08',
    expireDays: 30,
  },
  {
    title: `Factsheet 2`,
    subText: `Using the GDST illustration tool`,
    downloadLink: `/docs/2021-11 GDST_TPS_FactSheet_2.pdf`,
    downloadDocName: `2021-11 GDST_TPS_FactSheet_2.pdf`,
    dateAdded: '2021-11-08',
    expireDays: 30,
  },
  {
    title: `Opening letter`,
    subText: `The letter provided to you on 23 September 2021 opening consultation`,
    downloadLink: `/docs/2021-09-22 FINAL GDST_TPS_cover_letter.pdf`,
    downloadDocName: `2021-09-22 FINAL GDST_TPS_cover_letter.pdf`,
    dateAdded: '2021-05-25',
    expireDays: 10,
  },
  {
    title: `Consultation guide`,
    subText: `The consultation guide provided to you on 23 September 2021`,
    downloadLink: `/docs/2021-09 Final GDST consultation booklet.pdf`,
    downloadDocName: `2021-09 Final GDST consultation booklet.pdf`,
    dateAdded: '2021-05-25',
    expireDays: 20,
  },
];

export default documentsData;
