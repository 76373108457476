var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "questionFeedback" } }, [
    _c("div", { staticClass: "question-feedback" }, [
      _c("div", {
        staticClass: "question-feedback--overlay",
        on: {
          click: function($event) {
            return _vm.$emit("closeFeedbackModal")
          }
        }
      }),
      _c(
        "div",
        { staticClass: "question-feedback--content" },
        [_vm._t("title"), _vm._t("content")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }