var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm.isNew
      ? _c("div", { staticClass: "new-badge " }, [_vm._v("NEW")])
      : _vm._e(),
    _c("h2", [_vm._v(_vm._s(_vm.document.title))]),
    _c("p", [_vm._v(" " + _vm._s(_vm.document.subText) + " ")]),
    _vm.document.downloadDocName !== ""
      ? _c(
          "a",
          {
            attrs: {
              href: "" + _vm.document.downloadLink,
              download: "" + _vm.document.downloadDocName
            }
          },
          [
            _c("button", { staticClass: "btn btn--inverse" }, [
              _vm._v("Download")
            ])
          ]
        )
      : _c("a", { attrs: { href: "" + _vm.document.downloadLink } }, [
          _c("button", { staticClass: "btn btn--inverse" }, [
            _vm._v("Follow link")
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }