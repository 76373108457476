
















import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component
export default class DocumentBox extends Vue {
  @Prop() document!: any;

  private dateNow = null;

  public get isNew() {
    return this.dateNow.diff(this.document.dateAdded, 'd') < this.document.expireDays;
  }

  created() {
    this.dateNow = dayjs();
  }
}
