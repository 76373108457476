import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LandingPage2202 from '../views/LandingPage2202.vue';
import LandingPage from '../views/LandingPage.vue';
// import Check from '../views/Check.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'LandingPage2202',
    component: LandingPage2202,
  },
  {
    path: '/legacy',
    name: 'LandingPage',
    component: LandingPage,
  },
  // {
  //   path: '/check',
  //   name: 'Check',
  //   component: Check,
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
