var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "navbar", attrs: { id: "navbar" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "navbar--wrapper" }, [
          _c(
            "div",
            { staticClass: "selections", class: { open: _vm.showSideBar } },
            [
              _c("div", {
                staticClass: "sidebar--bg",
                class: { "fadein--bg": _vm.showSideBar },
                on: { click: _vm.closeSideBar }
              }),
              _c("div", { staticClass: "selections--links" }, [
                _vm._m(0),
                _c("div", { staticClass: "logo" }),
                _c(
                  "div",
                  { staticClass: "selections--links-items right-col" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "illustration-link",
                        attrs: { href: "#changeIllustration" }
                      },
                      [_vm._v("Illustration tool")]
                    ),
                    _c("div", { staticClass: "help" }, [
                      _c("a", [
                        _vm._v(" help "),
                        _c(
                          "span",
                          { staticClass: "help--icon" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "question-circle"] }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "help--dropdown" }, [
                        _c("ul", { staticClass: "dropdown--links" }, [
                          _vm._m(1),
                          _vm._m(2),
                          _c("li", { on: { click: _vm.goToLandingPage } }, [
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v(
                                "View " + _vm._s(_vm.getViewHubVersion) + " Hub"
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _c("div", { staticClass: "extra--link" }, [
                  _c("a", { attrs: { href: "#questionsAnswers" } }, [
                    _vm._v("Questions & Answers")
                  ]),
                  _c("a", { attrs: { href: "#importantDocs" } }, [
                    _vm._v("IMPORTANT DOCUMENTS")
                  ]),
                  _c("div", { on: { click: _vm.goToLandingPage } }, [
                    _vm._v("View " + _vm._s(_vm.getViewHubVersion) + " hub")
                  ])
                ])
              ])
            ]
          ),
          _c("div", { staticClass: "mobile-menu" }, [
            _c(
              "div",
              {
                staticClass: "menu--container",
                on: { click: _vm.handleSideBar }
              },
              [
                _c("div", { staticClass: "bar1" }),
                _c("div", { staticClass: "bar2" }),
                _c("div", { staticClass: "bar3" })
              ]
            ),
            _vm._m(3)
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "selections--links-items left-col" }, [
      _c("a", { attrs: { href: "#intro" } }, [_vm._v("Welcome")]),
      _c("a", { attrs: { href: "#videoGuides" } }, [_vm._v("Video Guides")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "#questionsAnswers" } }, [
        _vm._v("Questions & Answers")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "#importantDocs" } }, [
        _vm._v("IMPORTANT DOCUMENTS")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-mobile" }, [
      _c("img", { attrs: { src: "/svg/logo.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }