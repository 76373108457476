var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-2 understand--changes" },
    [
      _vm.showVideo
        ? _c("base-modal", {
            attrs: { modalOverlay: "video" },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        [
                          _c("Video", {
                            attrs: { videoToShow: _vm.videoToShow },
                            on: { closeModal: _vm.closeVideo }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1783734748
            )
          })
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "layout" }, [
          _c(
            "div",
            {
              ref: "understandChanges",
              staticClass: "understand--changes-content"
            },
            [
              _c("h2", { staticClass: "section--heading" }, [
                _vm._v("Video guides")
              ]),
              _c("h3", { staticClass: "section--sub--heading" }, [
                _vm._v(
                  " We understand that pensions can seem complex, so we've worked with independent pensions advisers LCP to create these five short videos to walk you through the proposed changes in detail and provide more pensions background. We recommend that you watch these before moving on to finding out exactly what the proposed changes could mean for you using the illustration tool below. "
                )
              ]),
              _c(
                "div",
                { ref: "card", staticClass: "card " },
                [
                  _c(
                    "agile",
                    {
                      ref: "carousel",
                      staticClass: "carousel",
                      attrs: { navButtons: false, speed: 800, dots: false },
                      on: { "after-change": _vm.updateSliderIndex }
                    },
                    _vm._l(_vm.proposeChanges, function(detail, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "card--body slide",
                          attrs: { "data-hash": detail.tab }
                        },
                        [
                          _c("div", { staticClass: "card--body-content" }, [
                            _c("div", { staticClass: "left-side content" }, [
                              _c("h3", { staticClass: "card--body-heading" }, [
                                _vm._v(" " + _vm._s(detail.title) + " ")
                              ]),
                              _c("div", { staticClass: "content--heading" }, [
                                _c("h3", [_vm._v(_vm._s(detail.contentTitle))])
                              ]),
                              _c("p", [_vm._v(_vm._s(detail.content))])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "right-side video-img",
                                style: {
                                  backgroundImage:
                                    "url('" + detail.thumbnail + "')"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleShowVideo(
                                      index,
                                      detail.title
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "video play-icon" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "play-circle"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c("section", { staticClass: "understand--changes-footer" }, [
                _c(
                  "ul",
                  { staticClass: "tab" },
                  _vm._l(_vm.proposeChanges, function(detail, i) {
                    return _c("li", { key: i, staticClass: "tab--steps" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn--equal",
                          class: {
                            "btn--active": _vm.sliderIndex === i
                          },
                          on: {
                            click: function($event) {
                              return _vm.updateCarousel(i)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(detail.title) + " ")]
                      )
                    ])
                  }),
                  0
                ),
                _vm._m(0)
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicate--section" }, [
      _c("p", { staticClass: "indicate--section-text" }, [
        _vm._v(" What does this mean for me? ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }