var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "wrapper" },
    [
      _vm.isFeedbackOuestionModal
        ? _c("QuestionFeedbackWrapper", {
            on: { closeFeedbackModal: _vm.closeFeedbackModal },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("FeedbackOrQuestion", {
                        on: { closeFeedbackModal: _vm.closeFeedbackModal }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1686677334
            )
          })
        : _vm._e(),
      _vm.showIntroVideo
        ? _c("base-modal", {
            attrs: { modalOverlay: "video" },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        [
                          _c("Video", {
                            attrs: {
                              videoToShow: _vm.videoToShow.type,
                              video: _vm.videoToShow.video
                            },
                            on: { closeModal: _vm.closeModal }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2864154433
            )
          })
        : _vm._e(),
      _c("section", { staticClass: "intro", attrs: { id: "intro" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "intro--content" }, [
            _c("div", { staticClass: "intro--content-details" }, [
              _vm._m(0),
              _c("div", { staticClass: " right-side" }, [
                _c(
                  "div",
                  { staticClass: "video-slider" },
                  [
                    _c(
                      "agile",
                      {
                        ref: "videoCarousel",
                        staticClass: "carousel",
                        attrs: { navButtons: false, speed: 800, dots: false },
                        on: { "after-change": _vm.updateSliderIndex }
                      },
                      _vm._l(_vm.introSliderContent, function(content, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "slide",
                            attrs: { "data-hash": content.tab }
                          },
                          [
                            content.type === "download" ||
                            content.type === "link"
                              ? _c("IntroDefaultHolderDownload", {
                                  attrs: { document: content }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "intro-video-tabs" },
                  _vm._l(_vm.introSliderContent, function(content, index) {
                    return _c(
                      "button",
                      {
                        key: index,
                        staticClass: "btn btn--equal",
                        class: {
                          "btn--active": _vm.sliderIndex === index
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateCarousel(index)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(content.title) + " ")]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        ])
      ]),
      _c(
        "section",
        {
          ref: "modellerSection",
          staticClass: "modeller--section",
          attrs: { id: "changeIllustration" }
        },
        [
          _c("modellerSection2202", { attrs: { live: true } }),
          _c("ScrollToSection", {
            nativeOn: {
              click: function($event) {
                return _vm.scrollToSection(".faqs")
              }
            }
          })
        ],
        1
      ),
      _c(
        "section",
        {
          ref: "informationSection",
          staticClass: "information--section",
          attrs: { id: "importantDocs" }
        },
        [_c("Information")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "details--text left-side" }, [
      _c("h1", { staticClass: "intro--content--heading" }, [_vm._v("Welcome")]),
      _c("h2", { staticClass: "subHeading" }, [
        _vm._v(" Update to changes to pensions for teachers ")
      ]),
      _c("p", [
        _vm._v(
          " We've designed this consultation hub to help you understand and engage with the full and final offer to change pensions for teachers at the GDST. Our teachers have the choice between continued membership of the TPS or joining the GDST Flexible Pension Plan. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }