var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "modeller--section-container" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "layout" },
        [
          _c("h2", { staticClass: "section--heading" }, [
            _vm._v("Illustration tool")
          ]),
          _c("div", { staticClass: "no-modeller" }, [
            _vm._v(
              " To use the illustration tool please view on an iPad or a Laptop/Desktop device "
            )
          ]),
          !_vm.live
            ? _c("div", { staticClass: "placeholder" }, [
                _c("p", [
                  _vm._v(
                    "We are currently updating the modeller, please check back soon."
                  )
                ])
              ])
            : [
                _c("div", { staticClass: "modeller" }, [
                  _vm.modellerAgreed
                    ? _c("div", { staticClass: "tab-container" }, [
                        _c(
                          "div",
                          {
                            class:
                              _vm.showTab === "input"
                                ? "tab active-tab"
                                : "tab",
                            on: {
                              click: function($event) {
                                return _vm.changeTab("input")
                              }
                            }
                          },
                          [_vm._v(" About you ")]
                        ),
                        _vm.data.showBenefit
                          ? _c(
                              "div",
                              {
                                class:
                                  _vm.showTab === "inputBenefits"
                                    ? "tab active-tab"
                                    : "tab",
                                on: {
                                  click: function($event) {
                                    return _vm.changeTab("inputBenefits")
                                  }
                                }
                              },
                              [_vm._v(" Your benefits ")]
                            )
                          : _vm._e(),
                        _vm.data.showOptions
                          ? _c(
                              "div",
                              {
                                class:
                                  _vm.showTab === "options"
                                    ? "tab active-tab"
                                    : "tab",
                                on: {
                                  click: function($event) {
                                    return _vm.changeTab("options")
                                  }
                                }
                              },
                              [_vm._v(" Your illustration ")]
                            )
                          : _vm._e(),
                        _vm.data.showOptions
                          ? _c(
                              "div",
                              {
                                class:
                                  _vm.showTab === "assumptions"
                                    ? "tab active-tab"
                                    : "tab",
                                on: {
                                  click: function($event) {
                                    return _vm.changeTab("assumptions")
                                  }
                                }
                              },
                              [_vm._v(" Assumptions ")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "modeller-inner-container",
                      class: { full: _vm.showTab === "options" }
                    },
                    [
                      !_vm.modellerAgreed
                        ? _c("div", { staticClass: "left-input" }, [_vm._m(0)])
                        : _vm._e(),
                      _vm.showTab === "input" && _vm.modellerAgreed
                        ? _c(
                            "div",
                            { staticClass: "left-input" },
                            [
                              _c("InputPanel", {
                                attrs: {
                                  dirtyInput: _vm.data.dirtyInputs,
                                  data: _vm.data.inputs
                                },
                                on: {
                                  "update-parent-value": _vm.updateInputsValue,
                                  "update-parent-error": _vm.updateInputsErrors
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showTab === "inputBenefits" && _vm.modellerAgreed
                        ? _c(
                            "div",
                            { staticClass: "left-input" },
                            [
                              _c("InputPanelBenefits", {
                                attrs: {
                                  dirtyInput: _vm.data.dirtyPensionInputs,
                                  data: _vm.data.inputs
                                },
                                on: {
                                  "update-parent-value": _vm.updateInputsValue,
                                  "update-parent-error": _vm.updateInputsErrors
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showTab === "options" && _vm.modellerAgreed
                        ? _c(
                            "div",
                            { staticClass: "left-options niceScroll" },
                            [
                              _c("OptionPanel2202", {
                                attrs: { data: _vm.data.options },
                                on: {
                                  "update-parent-value": _vm.updateOptionsValue
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showTab === "options" && _vm.modellerAgreed
                        ? _c(
                            "div",
                            { staticClass: "right" },
                            [
                              _c("OutputPanel2202", {
                                attrs: {
                                  member: _vm.data.inputs,
                                  options: _vm.data.options,
                                  illustration: _vm.data.output
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showTab === "assumptions" && _vm.modellerAgreed
                        ? _c(
                            "div",
                            { staticClass: "left-input" },
                            [
                              _c("ModellerAssumption2202", {
                                attrs: {
                                  pen80:
                                    _vm.data.inputs.benefits.accuredPension80 >
                                    0
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  !_vm.modellerAgreed
                    ? _c("div", { staticClass: "actions-container" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--inverse",
                            on: {
                              click: function($event) {
                                _vm.modellerAgreed = true
                              }
                            }
                          },
                          [_vm._v("Continue")]
                        )
                      ])
                    : _vm._e(),
                  _vm.showTab === "input" && _vm.modellerAgreed
                    ? _c("div", { staticClass: "actions-container" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--inverse",
                            on: {
                              click: function($event) {
                                return _vm.changeTab("inputBenefits")
                              }
                            }
                          },
                          [_vm._v("Continue")]
                        )
                      ])
                    : _vm._e(),
                  _vm.showTab === "inputBenefits" && _vm.modellerAgreed
                    ? _c("div", { staticClass: "actions-container" }, [
                        _c(
                          "div",
                          {
                            staticClass: "fill-fields-error",
                            class: { "show-error": _vm.fillFormError }
                          },
                          [_vm._v(" Please complete the required inputs ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--inverse",
                            on: {
                              click: function($event) {
                                return _vm.changeTab("options")
                              }
                            }
                          },
                          [_vm._v(" Show illustration ")]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._m(1)
              ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agreeWrapper" }, [
      _c("h2", [_vm._v("Getting more from the updated Illustration Tool")]),
      _c("p", [
        _vm._v(" You will need to "),
        _c("b", [
          _vm._v(
            "consider the outputs from the Illustration Tool in the context of your wider financial circumstances"
          )
        ]),
        _vm._v(
          ", as not all eventualities can be considered in this pension modeller. You might find it useful to read the “Assumptions” tab you'll see when you get to your illustration. "
        )
      ]),
      _c("p", [
        _vm._v(" Get more help by reading our "),
        _c(
          "a",
          {
            attrs: {
              href: "/docs/2022 04 - GDST - Illustration tool how to v2.pdf",
              download: "2022 04 - GDST - Illustration tool how to v2.pdf"
            }
          },
          [_vm._v("Illustration Tool guide")]
        ),
        _vm._v(
          ". It's a comprehensive overview of: the inputs, where to find the data you need, and the modeller's options and output. "
        )
      ]),
      _c("p", [
        _vm._v(
          " If you require individual advice you should speak with a financial adviser who is authorised by the Financial Conduct Authority, who will consider your overall financial circumstances and objectives. You can find a financial adviser near you by visiting Money Helper "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.moneyhelper.org.uk/en",
              target: "_blank"
            }
          },
          [_vm._v("https://www.moneyhelper.org.uk/en")]
        ),
        _vm._v(". ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modellerUpdates", attrs: { id: "modellerUpdates" } },
      [
        _c("h2", [_vm._v(" What’s new in the Illustration Tool? ")]),
        _c("ul", [
          _c("li", [
            _c("b", [
              _vm._v(
                "The underlying calculations and assumptions are unchanged"
              )
            ]),
            _vm._v(
              " – the updated Illustration Tool carries out calculations in a manner consistent with the previous Illustration Tool. "
            )
          ]),
          _c("li", [
            _c("b", [
              _vm._v(
                "We’ve updated the calculations to allow for the full and final offer from the GDST"
              )
            ]),
            _vm._v(
              ", including the two pension options set out in the Summary document you can find below and the proposed pay increases in September 2022 and September 2023. "
            )
          ]),
          _c("li", [
            _c("b", [
              _vm._v(
                "We’ve changed the default contributions it is assumed you’ll pay to the GDST Flexible Pension Plan"
              )
            ]),
            _vm._v(
              " when you first access the tool. It now calculates an individual contribution rate which minimises your expected change in take-home pay, so we’re comparing “like with like” as much as possible when we look at retirement income. If you prefer, you can change it back to the previous default of you paying contributions of 10% of your salary to the GDST Flexible Pension Plan under Option 2. "
            )
          ]),
          _c("li", [
            _c("b", [
              _vm._v(
                "We’ve updated how the tool assumes you’d like to drawdown benefits in the GDST Flexible Pension Plan"
              )
            ]),
            _vm._v(
              ". Previously, if you selected a retirement age before your State Pension Age the Tool assumed you’d want to use your GDST Flexible Pension Plan pot to “bridge the gap” between retirement age and when your State Pension is expected to kick in. It now assumes by default that you simply want to use your GDST Flexible Pension Plan pot under Option 2 so that your overall pension matches the income you might expect to get from the TPS under Option 1 were you to remain an active member in the TPS in the future. "
            )
          ]),
          _c("li", [
            _c("b", [
              _vm._v("We’ve assumed that TPS costs remain stable in the future")
            ]),
            _vm._v(
              ". Under the GDST’s full and final offer, salary increases for those who remain in the TPS could be adjusted upwards or downwards relative to those joining the GDST Flexible Pension Plan depending on how TPS costs develop over time. If TPS costs remain stable there would be no such adjustments. The Illustration Tool therefore does not illustrate the impact this could have on you. "
            )
          ]),
          _c("li", [
            _vm._v(
              " As with the previous version of the Illustration Tool, the benefits that you could receive from the final salary element of the TPS will in practice depend on the progression of your salary up to your retirement (or earlier leaving service). The Illustration Tool is designed to illustrate your TPS pension, but we do not have access to the detailed salary data that would be needed for an accurate calculation in all cases. "
            )
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }