






























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Slider from './../input/slider/Slider.vue';
import DatePickerInput from './../input/DatePickerInput.vue';
import FormInput from './../input/form/FormInput.vue';
import FormInputDate from './../input/form/FormInputDate.vue';
import RadioInput from './../input/radio/RadioInput.vue';
import PopUpTextContent from '../PopUpTextContent.vue';
import moment from 'moment';
import Video from '@/components/UI/Video.vue';

@Component({
  components: {
    Slider,
    DatePickerInput,
    FormInput,
    RadioInput,
    FormInputDate,
    PopUpTextContent,
    Video,
  },
})
export default class InputPanel extends Vue {
  public isPopUp = false;
  @Prop() readonly data!: object;
  @Prop() readonly dirtyInput!: boolean;

  public showVideo = false;
  public videoToShow =
    'https://gdststprod.blob.core.windows.net/videos/impact-on-you-20-september.mp4?sp=r&st=2021-12-31T10:07:06Z&se=2022-12-31T18:07:06Z&spr=https&sv=2020-08-04&sr=b&sig=yv%2FxC72iP4uOxJdICzbSiR9oM8T95M3HX1iltjD6rzo%3D';

  private minAge = moment()
    .subtract(75, 'years')
    .toDate();
  private maxAge = moment()
    .subtract(16, 'years')
    .toDate();

  updateValue(key: string, value: any) {
    this.$emit('update-parent-value', key, value);
  }

  updateError(key: string, value: boolean) {
    this.$emit('update-parent-error', key, value);
  }

  showPopUp() {
    this.isPopUp = true;
  }

  closeModal() {
    this.isPopUp = false;
  }

  closeVideo() {
    this.showVideo = false;
  }

  handleShowVideo() {
    this.showVideo = true;
    const pageV = `video`;
    // eslint-disable-next-line
    this.$gtag.screenview({
      app_name: 'hub',
      screen_name: pageV,
    });
  }
}
