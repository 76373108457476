var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "slider-wrapper" }, [
      _c(
        "div",
        { staticClass: "left-wrapper" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    content: _vm.tooltipData,
                    placement: "bottom",
                    classes: ["slider-tooltip"],
                    targetClasses: ["it-has-a-tooltip"],
                    offset: "10px"
                  },
                  expression:
                    "{\n          content: tooltipData,\n          placement: 'bottom',\n          classes: ['slider-tooltip'],\n          targetClasses: ['it-has-a-tooltip'],\n          offset: '10px',\n        }"
                }
              ],
              staticClass: "slider-name"
            },
            [
              _vm._v(" " + _vm._s(_vm.inputName) + " "),
              _c("font-awesome-icon", {
                staticClass: "infoy",
                attrs: { icon: ["far", "info-circle"] }
              })
            ],
            1
          ),
          _c("vSlider", {
            ref: "slider",
            staticClass: "my-slider",
            attrs: {
              dotSize: 20,
              tooltip: "none",
              dragOnClick: "",
              min: _vm.minValue,
              max: _vm.maxValue,
              interval: _vm.step
            },
            on: { "drag-end": _vm.updateValueFromSlider },
            model: {
              value: _vm.inputValue,
              callback: function($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "right-wrapper" }, [
        _c("input", {
          class: { "input-error": _vm.$v.$anyError },
          attrs: {
            type: "text",
            name: _vm.inputName,
            maxlength: _vm.unit === "percentage" ? 3 : ""
          },
          domProps: { value: _vm.inputValue },
          on: {
            input: function($event) {
              return _vm.updateValueFromInput($event.target.value)
            }
          }
        }),
        _vm.unit === "percentage"
          ? _c("div", { staticClass: "unit-text" }, [_vm._v("%")])
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "error-messages" }, [
      _vm.isErrors.required && _vm.$v.$dirty
        ? _c("small", [_vm._v("Field must not be empty")])
        : _vm._e(),
      _vm.isErrors.numeric && _vm.$v.$dirty
        ? _c("small", [
            _vm._v("Field must be numeric without any commas ' , '")
          ])
        : _vm._e(),
      _vm.isErrors.between &&
      _vm.$v.$dirty &&
      !_vm.isErrors.numeric &&
      !_vm.isErrors.required
        ? _c("small", [
            _vm._v(
              _vm._s("Must be between " + _vm.minValue + " and " + _vm.maxValue)
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }