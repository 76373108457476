




























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UserSelection extends Vue {
  selectionType(selection: string) {
    this.$emit('showQuestionListOrFeedback', selection);
  }
}
