



























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { required, between, decimal } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';

//here
@Component({})
export default class FormInput extends Vue {
  @Prop() readonly formLabel!: string;
  @Prop() readonly inputType!: string;
  @Prop() readonly helpText!: string;
  @Prop() readonly minValue!: number;
  @Prop() readonly maxValue!: number;
  @Prop() readonly dataKey!: string;
  @Prop() readonly dataValue!: any;
  @Prop() readonly dirtyInput!: boolean;
  @Prop() readonly averageReq!: boolean;
  @Prop() readonly requiredValue!: string;
  @Prop() readonly compareValue!: string;

  isActive = false;
  isInFocus = false;
  error = true;
  inFocus = false;
  texting = '';
  formValue = '';
  requiredDependantValue = false;

  @Validations()
  validations = {
    formValue: {
      required,
      decimal,
      between: between(this.minValue, this.maxValue),
    },
  };

  get formattedValueStyle() {
    if (!this.$v.formValue.decimal && this.dirtyInput && this.$v.$dirty) {
      return 'formatted-value numeric-error';
    }
    if (
      (!this.$v.formValue.required && this.dirtyInput && this.$v.$dirty) ||
      (!this.$v.formValue.between && this.dirtyInput)
    ) {
      return 'formatted-value value-error';
    }
    if (!this.$v.formValue.between && this.dirtyInput) {
      return 'formatted-value value-error ';
    }
    return 'formatted-value';
  }

  hideFormatting() {
    this.inFocus = true;
    (this.$refs.inputValue as HTMLBaseElement).focus();
  }
  updateFormValue(e) {
    this.formValue = e.target.value;
    this.$v.formValue.$touch();
    this.$emit('update-parent-value', this.dataKey, parseFloat(this.formValue));
    this.$emit('update-parent-error', this.dataKey, this.$v.$anyError);
  }

  get formatValue() {
    if (this.formValue === '') return '';
    switch (this.inputType) {
      case 'pounds':
        return `£${this.formatNumber(this.formValue)}`;
      case 'percentage':
        return `${this.formatNumber(this.formValue)}%`;
      default:
        return `${this.formatNumber(this.formValue)}`;
    }
  }

  formatNumber(amount, decimalCount = 0, decimal = '.', thousands = ',') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      const i: any = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      // console.log(e);
    }
  }

  created() {
    this.formValue = this.dataValue;
  }

  @Watch('dirtyInput')
  makeInputsDirty(newDirty: boolean) {
    if (newDirty) {
      this.$v.formValue.$touch();
    }
  }
  @Watch('requiredValue')
  triggerError(value: number) {
    if (!this.averageReq) return;
    if (value !== 0) {
      this.requiredDependantValue = true;
    } else {
      this.requiredDependantValue = false;
    }

    if (value !== 0 && this.dataValue !== 0) {
      this.requiredDependantValue = false;
    }
  }
  @Watch('dataValue')
  resetError(value: number) {
    if (value !== 0) {
      this.requiredDependantValue = false;
    }
  }
}
