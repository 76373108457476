var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "feedback-question-header" }, [
      _c("h3", { staticClass: "section-heading" }, [
        _vm._v(" Please submit your "),
        _c("span", [_vm._v(_vm._s(_vm.setFormText))]),
        _vm._v(" below ")
      ])
    ]),
    _c("form", { staticClass: "form" }, [
      _c("div", { staticClass: "name--section" }, [
        _c(
          "div",
          {
            staticClass: "name--section-firstname input-field",
            class: { "form-error-display": _vm.$v.$dirty }
          },
          [
            _c("label", [_vm._v(" First Name ")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.firstName,
                  expression: "formData.firstName",
                  modifiers: { trim: true }
                }
              ],
              domProps: { value: _vm.formData.firstName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "firstName",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            !_vm.$v.formData.firstName.required && _vm.$v.$dirty
              ? _c("div", { staticClass: "form-error" }, [
                  _vm._v(" First name is required. ")
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "name--section-lastname input-field",
            class: { "form-error-display": _vm.$v.$dirty }
          },
          [
            _c("label", [_vm._v(" Last Name ")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.lastName,
                  expression: "formData.lastName",
                  modifiers: { trim: true }
                }
              ],
              domProps: { value: _vm.formData.lastName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "lastName", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            !_vm.$v.formData.lastName.required && _vm.$v.$dirty
              ? _c("div", { staticClass: "form-error" }, [
                  _vm._v(" Last name is required. ")
                ])
              : _vm._e()
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "input-field",
          class: { "form-error-display": _vm.$v.$dirty }
        },
        [
          _c("label", [_vm._v(" Email ")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.formData.email,
                expression: "formData.email",
                modifiers: { trim: true }
              }
            ],
            domProps: { value: _vm.formData.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.formData, "email", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          !_vm.$v.formData.email.required && _vm.$v.$dirty
            ? _c("div", { staticClass: "form-error" }, [
                _vm._v(" Please enter an email address. ")
              ])
            : _vm._e(),
          !_vm.$v.formData.email.email && _vm.$v.$dirty
            ? _c("div", { staticClass: "form-error" }, [
                _vm._v(" Please enter a valid email. ")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "input-field",
          class: { "form-error-display": _vm.$v.$dirty }
        },
        [
          _c("label", [_vm._v(" Selected School ")]),
          _c("multiselect", {
            attrs: {
              options: _vm.schoolList,
              searchable: false,
              "show-labels": false
            },
            model: {
              value: _vm.formData.school,
              callback: function($$v) {
                _vm.$set(_vm.formData, "school", $$v)
              },
              expression: "formData.school"
            }
          }),
          !_vm.$v.formData.school.required && _vm.$v.$dirty
            ? _c("div", { staticClass: "form-error" }, [
                _vm._v(" Please choose a school ")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.path === "question"
        ? _c(
            "div",
            {
              staticClass: "subject",
              class: { "form-error-display": _vm.$v.$dirty }
            },
            [
              _c("label", [_vm._v("Subject")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.setSubjectInput,
                    expression: "setSubjectInput"
                  }
                ],
                attrs: { type: "text", placeholder: "Please add a subject" },
                domProps: { value: _vm.setSubjectInput },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.setSubjectInput = $event.target.value
                  }
                }
              })
            ]
          )
        : _vm._e(),
      _c("div", { class: { "form-error-display": _vm.$v.$dirty } }, [
        _c("label", [_vm._v(_vm._s(_vm.setFormText))]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.feedback,
              expression: "formData.feedback"
            }
          ],
          staticClass: "form--textarea",
          attrs: { cols: "30", rows: "5" },
          domProps: { value: _vm.formData.feedback },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "feedback", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        !_vm.$v.formData.feedback.required && _vm.$v.$dirty
          ? _c("div", { staticClass: "form-error" }, [
              _vm._v(" This field cannot be empty ")
            ])
          : _vm._e(),
        !_vm.$v.formData.feedback.minLength && _vm.$v.$dirty
          ? _c("div", { staticClass: "form-error" }, [
              _vm._v(
                " Minimum character is " +
                  _vm._s(_vm.$v.formData.feedback.$params.minLength.min) +
                  ". "
              )
            ])
          : _vm._e()
      ]),
      _c(
        "button",
        {
          staticClass: "btn btn--equal",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.submitFeedback.apply(null, arguments)
            }
          }
        },
        [
          _vm._v(" Submit "),
          _c(
            "span",
            { staticClass: "loader-icon" },
            [
              _vm.isSubmiting
                ? _c("font-awesome-icon", {
                    attrs: { spin: "", icon: ["fad", "spinner-third"] }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }