const documentsData = [
  {
    title: `Questions and Answers`,
    subText: `Updated on 20th May 2022`,
    downloadLink: `/docs/TPS Q&A Pensions - 20th May Update.pdf`,
    downloadDocName: `TPS Q&A Pensions - 20th May Update.pdf`,
    dateAdded: '2022-05-20',
    expireDays: 30,
  },
  {
    title: `Illustration Tool Guide`,
    subText: `A guide on how to use the tool`,
    downloadLink: `/docs/2022 04 - GDST - Illustration tool how to v2.pdf`,
    downloadDocName: `2022 04 - GDST - Illustration tool how to v2.pdf`,
    dateAdded: '2022-04-01',
    expireDays: 30,
  },
  {
    title: 'Helpful Checklist',
    subText: `Use this checklist to help you with your choice`,
    downloadLink: `/docs/TPS - Choosing your pension option.pdf`,
    downloadDocName: `TPS - Choosing your pension option.pdf`,
    dateAdded: '2022-03-30',
    expireDays: 30,
  },
  {
    title: `FPP Booklet`,
    subText: `The GDST Flexible Pension Plan Booklet`,
    downloadLink: `/docs/2022-03 The GDST Flexible Pension Plan for offer letter.pdf`,
    downloadDocName: `2022-03 The GDST Flexible Pension Plan for offer letter.pdf`,
    dateAdded: '2022-03-17',
    expireDays: 30,
  },
  {
    title: `Summary`,
    subText: `A summary of the full and final offer sent on 11 March 2022`,
    downloadLink: `/docs/TPS-Summary-of-proposal.pdf`,
    downloadDocName: `TPS-Summary-of-proposal.pdf`,
    dateAdded: '2022-03-11',
    expireDays: 30,
  },


  {
    title: `Factsheet 1`,
    subText: `The proposed GDST Flexible Pension Plan - key features. Updated on 11 March 2022`,
    downloadLink: `/docs/GDST_TPS_FactSheet1.pdf`,
    downloadDocName: `GDST_TPS_FactSheet1.pdf`,
    dateAdded: '2022-03-11',
    expireDays: 30,
  },
  {
    title: `More Information`,
    subText: `Additional pension-related content available on the legacy hub`,
    downloadLink: `/legacy`,
    downloadDocName: ``,
    dateAdded: '2022-03-03',
    expireDays: 30,
  },
  {
    title: 'TPS Site',
    subText: "A link to the Teachers' Pension Scheme website",
    downloadLink: 'https://www.teacherspensions.co.uk/',
    downloadDocName: ``,
    dateAdded: '2022-03-03',
    expireDays: 30,
  },
];

export default documentsData;
