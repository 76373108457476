


































import { Component, Vue, Prop } from 'vue-property-decorator';
import Loading from '@/components/UI/Loading.vue';

@Component({
  components: {
    Loading,
  },
})
export default class VideoComponent extends Vue {
  @Prop(String) videoToShow!: string;
  @Prop(String) video?: string;
  vimeoData = null;
  tabInindex = 0;
  activeTab = 'video';
  // videoWidth = '400';
  videoHeight = '500';

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  async mounted() {
    // @ts-ignore
    switch (this.$mq) {
      case 'sm':
        this.videoHeight = '200';
        break;
      case 'md':
        this.videoHeight = '500';
        break;
      case 'lg':
        break;
      default:
        'lg';
    }
  }
}
