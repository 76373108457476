import Vue from 'vue';

interface ModalStore {
  showModal: boolean;
  component: string;
}
interface FeedbackOrQuestionStore {
  showFeedbackOrQuestion: boolean;
}

const modal: ModalStore = Vue.observable({
  showModal: false,
  component: '',
});

export const terms = Vue.observable({
  show: false,
});

export const showTerms = () => {
  terms.show = true;
};
export const closeTerms = () => {
  terms.show = false;
};

export const cookie = Vue.observable({
  show: false,
});

export const showCookie = () => {
  cookie.show = true;
};
export const closeCookie = () => {
  cookie.show = false;
};

export const faq = Vue.observable({
  more: false,
});

export const faqShowMore = () => {
  faq.more = true;
};

export const faqShowLess = () => {
  faq.more = false;
};

export const questionOrFeedback: FeedbackOrQuestionStore = Vue.observable({
  showFeedbackOrQuestion: false,
});

export const handleShowModal = (component: any) => ({
  showModal: modal.showModal = true,
  component,
});

export const handleCloseModal = () => modal.showModal;

export const showFeedbackOrQuestion = () => {
  questionOrFeedback.showFeedbackOrQuestion = true;
};
