var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "faqs",
      class: { "no-results-height": _vm.filterResults.length === 0 }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "layout" }, [
          _c("h2", { staticClass: "section--heading" }, [
            _vm._v("Questions & answers")
          ]),
          _c("div", { staticClass: "faqs--content" }, [
            _c("div", { staticClass: "category--search" }, [
              _c("div", { staticClass: "category--search-content" }, [
                _c("div", { staticClass: "inner--search" }, [
                  _c("input", {
                    attrs: {
                      placeholder: "Search our frequently asked questions"
                    },
                    domProps: { value: _vm.catSearch },
                    on: { input: _vm.updateCatSearch }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "search", attrs: { role: "button" } },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fal", "search"] }
                    })
                  ],
                  1
                )
              ]),
              _vm.catSearch === ""
                ? _c("div", { staticClass: "category--headers" })
                : _c("div", { staticClass: "filter" }, [
                    _vm._v(" Filtering results on "),
                    _c("span", { staticClass: "filterTerm" }, [
                      _vm._v('"' + _vm._s(_vm.catSearch) + '"')
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "clearFilter",
                        on: {
                          click: function($event) {
                            _vm.catSearch = ""
                          }
                        }
                      },
                      [_vm._v("remove filter")]
                    )
                  ])
            ]),
            _c("div", { staticClass: "questionAnswers" }, [
              _vm.filterResults.length === 0
                ? _c("div", { staticClass: "noResults" }, [
                    _vm._v(" No results found ")
                  ])
                : _c(
                    "div",
                    {
                      ref: "faqContainer",
                      staticClass: "faq-container",
                      class: [
                        { "show-more": _vm.showMore },
                        { "no-filtered-results": _vm.isShowMoreBtn }
                      ]
                    },
                    _vm._l(_vm.filterResults, function(item, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "faq-category" },
                        [
                          _c("div", { staticClass: "faq-category--heading" }, [
                            _c("h3", [_vm._v(_vm._s(item.category))])
                          ]),
                          _vm._l(item.faqs, function(faq, i) {
                            return _c("faqList", {
                              key: i,
                              attrs: {
                                faq: faq,
                                catSearch: _vm.catSearch,
                                openOverride: faq.openOverride
                              }
                            })
                          })
                        ],
                        2
                      )
                    }),
                    0
                  ),
              _vm.isShowMoreBtn
                ? _c("div", { staticClass: "view-button--holder" }, [
                    _c(
                      "button",
                      { staticClass: "btn", on: { click: _vm.handleShowMore } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.showMore ? "View Less" : "View More") +
                            " "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }