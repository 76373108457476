class RetirementAgeConstraints {
  nra: number;
  restrictedEarliestRetirementAge: boolean;
  earliestRetirementAge: number;
  latestRetirementAge: number;
  defaultRetirementAge: number;

  constructor(agenow: number, nra: number) {
    this.nra = nra;
    this.earliestRetirementAge = Math.max(55, Math.floor(agenow) + 1);
    this.defaultRetirementAge = Math.max(this.nra, this.earliestRetirementAge);
    this.latestRetirementAge = 75;
    this.restrictedEarliestRetirementAge = this.earliestRetirementAge !== 55;
  }
}

class PensionOutputs {
  totalDeferredTPSbenefits: number[]; // retirement to 100
  deferredTPS80ths: number[];
  deferredTPS60ths: number[];
  deferredTPSCARE: number[];
  statePension: number[];
  theoreticalFutureTPSbenefits: number[];
  theoreticalFutureTPSbenefitsIncStatePension: number[];
  illustratedTotalDCpotAtRetirement: number;
  illustratedDCincome: number[];
  illustratedLumpSum: number;
  illustratedLateRetirementPaymentTaxFree: number;
  illustratedLateRetirementPaymentTaxed: number;
  illustratedLumpSumTPS: number;
  theoreticalFutureTPSbenefitsIncStatePensionAtRetirement: number;
  remainingDCPot: number;

  constructor() {
    this.totalDeferredTPSbenefits = new Array(100).fill(0);
    this.theoreticalFutureTPSbenefitsIncStatePension = new Array(100).fill(0);
    this.deferredTPS80ths = new Array(100).fill(0);
    this.deferredTPS60ths = new Array(100).fill(0);
    this.deferredTPSCARE = new Array(100).fill(0);
    this.statePension = new Array(100).fill(0);
    this.theoreticalFutureTPSbenefits = new Array(100).fill(0);
    this.illustratedDCincome = new Array(100).fill(0);
    this.remainingDCPot = 0;
  }
}

class PostTransitionalOutput {
  illustratedSalary: number;
  illustratedCashSuppliment: number;
  approxTakehomePay: number;
  approxTakehomePayTPS: number;
  approxTax: number;
  memberContribution: number;
  memberContributionTPS: number;
  gdstContribution: number;
}

class IllustrationOutput {
  retirementAgeConstraints: RetirementAgeConstraints;
  pensions: PensionOutputs;
  inServiceImpact: PostTransitionalOutput;
  lifeExpectancy: number;
  retireInConsultation: boolean;

  constructor(agenow: number, nra: number, lifeExpectancy: number) {
    this.retirementAgeConstraints = new RetirementAgeConstraints(agenow, nra);
    this.pensions = new PensionOutputs();
    this.inServiceImpact = new PostTransitionalOutput();
    this.lifeExpectancy = lifeExpectancy;
    this.retireInConsultation = false;
  }
}

export { RetirementAgeConstraints, PensionOutputs, PostTransitionalOutput, IllustrationOutput };
