



































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import InputPanel from '@/components/panels/InputPanel.vue';
import InputPanelBenefits from '@/components/panels/InputPanelBenefits.vue';
import OptionPanel2202 from '@/components/panels/OptionPanel2202.vue';
import OutputPanel2202 from '@/components/panels/OutputPanel2202.vue';
import ModellerAssumption2202 from '@/components/ModellerAssumption2202.vue';
import {
  UserInputs,
  UserRetirementOptions,
  UserAccumulationOptions,
  UserAccumulationIllustration,
} from '@/common/UserInputs2202';
import calcs from '@/calculations/calculation2202';
import moment from 'moment';

@Component({
  components: {
    InputPanel,
    InputPanelBenefits,
    OptionPanel2202,
    OutputPanel2202,
    ModellerAssumption2202,
  },
})
export default class ModellerSection extends Vue {
  @Prop(Boolean) live!: boolean;
  data = {
    inputs: {
      general: {
        gender: null,
        dateOfBirth: '',
        currentFullTimeSalary: 0,
        currentPartTimeProportion: 100,
        isPartTime: 'No',
      },
      benefits: {
        dateOfBenefitData: '',
        accuredPension80: 0,
        averageSalary80: 0,
        accuredPension60: 0,
        averageSalary60: 0,
        accruedCARE: 0,
      },
    },
    errors: {
      general: {
        gender: true,
        dateOfBirth: true,
        currentFullTimeSalary: true,
        currentPartTimeProportion: false,
        isPartTime: false,
      },
      benefits: {
        dateOfBenefitData: true,
        accuredPension80: false,
        averageSalary80: false,
        accuredPension60: false,
        averageSalary60: false,
        accruedCARE: false,
      },
    },
    options: {
      rewardOption: 'Pension Max', //Cash Max, Cash Plus, Pension Plus, Pension Max
      memberDCcontribution: 10,
      matchTakehomeSalary: 'Yes',
      salaryGrowth: 0,
      investmentGrowth: 'Medium',
      minRetirementAge: 55,
      retirementAge: 65,
      maxRetirementAge: 75,
      maxTaxFreeLumpSum: 'Yes',
      illustrateDC: 'Drawdown',
      drawdownType: 'Target TPS benefit level',
      targetRetirementIncome: 10000,
    },
    showBenefit: false,
    showOptions: false,
    dirtyInputs: false,
    dirtyPensionInputs: false,
    output: null,
  };
  // data: UserDetailsOptions = new UserDetailsOptions('28.12.1975');

  showTab = 'input';
  modellerAgreed = false;
  fillFormError = false;

  mounted() {
    // this.data.inputs.benefits.dateOfBenefitData = moment()
    //   .subtract(1, 'months')
    //   .endOf('month')
    //   .format('DD/MM/YYYY');
  }

  changeTab(tab: string) {
    // set all inputs as dirty
    //checks if there are any falsy values in input object
    if (this.showTab === 'input') {
      this.data.dirtyInputs = true;
    }

    if (this.showTab === 'inputBenefits') {
      this.data.dirtyPensionInputs = true;
    }

    if (tab === 'input' || tab === 'inputBenefits') {
      this.data.showOptions = false;
    }

    if (this.showTab === 'input') {
      if (
        !Object.keys(this.data.inputs.general).some((k) => this.data.errors.general[k] === true)
      ) {
        this.showTab = tab;
        this.data.dirtyInputs = false;
        this.data.showBenefit = true;
      }
    } else if (this.showTab === 'inputBenefits') {
      if (tab === 'input') {
        this.showTab = tab;
        this.data.dirtyInputs = false;
      } else if (
        !Object.keys(this.data.inputs.benefits).some((k) => this.data.errors.benefits[k] === true)
      ) {
        if (this.checkRequiredValuesOnBenfits()) {
          // return out if it fails
          this.showTab = tab;
          this.updateConstraints();
          this.data.options.minRetirementAge = this.data.output.retirementAgeConstraints.earliestRetirementAge;
          this.data.options.maxRetirementAge = this.data.output.retirementAgeConstraints.latestRetirementAge;
          this.data.options.retirementAge = this.data.output.retirementAgeConstraints.defaultRetirementAge;
          this.updateCalculation();
          //this.data.dirtyPensionInputs = true;

          this.data.showOptions = true;
          this.fillFormError = false;
          this.data.dirtyPensionInputs = false;
        } else {
          this.fillFormError = true;
          return;
        }
      } else {
        this.fillFormError = true;
      }
    } else {
      this.updateCalculation();
      this.showTab = tab;
    }

    this.$gtag.screenview({
      app_name: 'modeller',
      screen_name: tab,
    });
  }

  checkRequiredValuesOnBenfits() {
    if (
      this.data.inputs.benefits.accuredPension80 === 0 &&
      this.data.inputs.benefits.accuredPension60 === 0 &&
      this.data.inputs.benefits.accruedCARE === 0
    )
      return false;
    if (
      this.data.inputs.benefits.accuredPension80 !== 0 &&
      this.data.inputs.benefits.averageSalary80 === 0
    )
      return false;
    if (
      this.data.inputs.benefits.accuredPension60 !== 0 &&
      this.data.inputs.benefits.averageSalary60 === 0
    )
      return false;
    return true;
  }
  updateInputsErrors(key: string, value: boolean) {
    if (this.showTab === 'input') {
      this.data.errors.general[key] = value;
    } else {
      this.data.errors.benefits[key] = value;
    }
  }

  updateInputsValue(key: string, value: any) {
    if (this.showTab === 'input') {
      this.data.inputs.general[key] = value;
    } else {
      this.data.inputs.benefits[key] = value;
      this.fillFormError = false;
    }
    //calc
    //map
    //if (this.data.dirtyPensionInputs) this.updateCalculation();
  }
  updateOptionsValue(key: string, value: any) {
    this.data.options[key] = value;
    //calc
    //map
    this.updateCalculation();
  }

  updateCalculation() {
    const userInput: UserInputs = {
      gender: this.data.inputs.general.gender,
      dateOfBirth: this.data.inputs.general.dateOfBirth,
      currentFullTimeSalary: this.data.inputs.general.currentFullTimeSalary,
      currentPartTimeProportion:
        this.data.inputs.general.isPartTime == 'No'
          ? 100
          : this.data.inputs.general.currentPartTimeProportion,
      accuredPension80: this.data.inputs.benefits.accuredPension80,
      averageSalary80: this.data.inputs.benefits.averageSalary80,
      accuredPension60: this.data.inputs.benefits.accuredPension60,
      averageSalary60: this.data.inputs.benefits.averageSalary60,
      accruedCARE: this.data.inputs.benefits.accruedCARE,
      dateOfPensionData: this.data.inputs.benefits.dateOfBenefitData,
    };

    const userAccumulationOptions: UserAccumulationOptions = {
      rewardOption: this.data.options.rewardOption,
      memberDCcontribution: this.data.options.memberDCcontribution,
      matchTakehomeSalary: this.data.options.matchTakehomeSalary === 'Yes',
    };

    const userRetirementOptions: UserRetirementOptions = {
      retirementAge: this.data.options.retirementAge,
      maxTaxFreeLumpSum: this.data.options.maxTaxFreeLumpSum === 'Yes',
      illustrateDC: this.data.options.illustrateDC, // Annuity or Drawdown
      drawdownType:
        this.data.options.drawdownType === 'Target TPS benefit level' ? 'Matching' : 'Custom',
      retirementTarget: this.data.options.targetRetirementIncome,
    };

    const userAccumulationIllustration: UserAccumulationIllustration = {
      salaryGrowth: this.data.options.salaryGrowth,
      investmentGrowth: this.data.options.investmentGrowth,
    };

    this.data.output = calcs.calculateBenefits(
      userInput,
      userRetirementOptions,
      userAccumulationOptions,
      userAccumulationIllustration,
    );

    if (this.data.options.drawdownType === 'Target TPS benefit level') {
      //this.data.options.targetRetirementIncome = this.data.output.pensions.matchingTarget;
      this.data.options.targetRetirementIncome =
        Math.round(this.data.output.pensions.matchingTarget / 100) * 100;
    }

    if (this.data.options.matchTakehomeSalary && this.data.options.rewardOption === 'Pension Max') {
      this.data.options.memberDCcontribution = this.data.output.inServiceImpact.memeberContributionPC;
    }
  }

  updateConstraints() {
    const userInput: UserInputs = {
      gender: this.data.inputs.general.gender,
      dateOfBirth: this.data.inputs.general.dateOfBirth,
      currentFullTimeSalary: this.data.inputs.general.currentFullTimeSalary,
      currentPartTimeProportion:
        this.data.inputs.general.isPartTime == 'No'
          ? 100
          : this.data.inputs.general.currentPartTimeProportion,
      accuredPension80: this.data.inputs.benefits.accuredPension80,
      averageSalary80: this.data.inputs.benefits.averageSalary80,
      accuredPension60: this.data.inputs.benefits.accuredPension60,
      averageSalary60: this.data.inputs.benefits.averageSalary60,
      accruedCARE: this.data.inputs.benefits.accruedCARE,
      dateOfPensionData: this.data.inputs.benefits.dateOfBenefitData,
    };

    const userAccumulationOptions: UserAccumulationOptions = {
      rewardOption: this.data.options.rewardOption,
      memberDCcontribution: this.data.options.memberDCcontribution,
      matchTakehomeSalary: this.data.options.matchTakehomeSalary === 'Yes',
    };

    const userRetirementOptions: UserRetirementOptions = {
      retirementAge: this.data.options.retirementAge,
      maxTaxFreeLumpSum: this.data.options.maxTaxFreeLumpSum === 'Yes',
      illustrateDC: this.data.options.illustrateDC, // Annuity or Drawdown
      retirementTarget: this.data.options.targetRetirementIncome,
      drawdownType:
        this.data.options.drawdownType === 'Target TPS benefit level' ? 'Matching' : 'Custom',
    };

    const userAccumulationIllustration: UserAccumulationIllustration = {
      salaryGrowth: this.data.options.salaryGrowth,
      investmentGrowth: this.data.options.investmentGrowth,
    };

    this.data.output = calcs.calculateConstraints(
      userInput,
      userRetirementOptions,
      userAccumulationOptions,
      userAccumulationIllustration,
    );
  }
}
