var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-pannel-wrapper" }, [
    _c(
      "div",
      { staticClass: "row-1" },
      [
        _vm._m(0),
        _c("FormInputDate", {
          attrs: {
            dataKey: "dateOfBenefitData",
            formLabel: "Date benefit statement calculated to (dd/mm/yyyy)",
            dirtyInput: _vm.dirtyInput,
            dataValue: _vm.data.benefits.dateOfBenefitData,
            minDate: _vm.minDate,
            maxDate: _vm.maxDate
          },
          on: {
            "update-parent-value": _vm.updateValue,
            "update-parent-error": _vm.updateError
          }
        }),
        _c("FormInput", {
          attrs: {
            minValue: 0,
            maxValue: 1000000,
            dirtyInput: _vm.dirtyInput,
            inputType: "pounds",
            dataKey: "accuredPension80",
            formLabel: "Annual Pension in the 80th Final Salary Arrangement",
            dataValue: _vm.data.benefits.accuredPension80,
            helpText:
              "If you joined the TPS before 1 January 2007 you should have benefits in this Arrangement."
          },
          on: {
            "update-parent-value": _vm.updateValue,
            "update-parent-error": _vm.updateError
          }
        }),
        _c("FormInput", {
          attrs: {
            minValue: 0,
            maxValue: 1000000,
            dirtyInput: _vm.dirtyInput,
            inputType: "pounds",
            dataKey: "averageSalary80",
            formLabel: "Average Salary for the 80th Final Salary Arrangement",
            averageReq: true,
            requiredValue: _vm.data.benefits.accuredPension80,
            dataValue: _vm.data.benefits.averageSalary80,
            compareValue: _vm.data.general.currentFullTimeSalary,
            helpText:
              "If you joined the TPS before 1 January 2007 you should have benefits in this Arrangement."
          },
          on: {
            "update-parent-value": _vm.updateValue,
            "update-parent-error": _vm.updateError
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row-2" },
      [
        _c("FormInput", {
          attrs: {
            minValue: 0,
            maxValue: 1000000,
            dirtyInput: _vm.dirtyInput,
            inputType: "pounds",
            dataKey: "accuredPension60",
            formLabel: "Annual Pension in the 60th Final Salary Arrangement",
            dataValue: _vm.data.benefits.accuredPension60,
            helpText:
              "If you joined the TPS between 1 January 2007 and 31 March 2015 you should have benefits in this Arrangement."
          },
          on: {
            "update-parent-value": _vm.updateValue,
            "update-parent-error": _vm.updateError
          }
        }),
        _c("FormInput", {
          attrs: {
            minValue: 0,
            maxValue: 1000000,
            dirtyInput: _vm.dirtyInput,
            inputType: "pounds",
            dataKey: "averageSalary60",
            formLabel: "Average Salary for the 60th Final Salary Arrangement",
            averageReq: true,
            requiredValue: _vm.data.benefits.accuredPension60,
            compareValue: _vm.data.general.currentFullTimeSalary,
            dataValue: _vm.data.benefits.averageSalary60,
            helpText:
              "If you joined the TPS between 1 January 2007 and 31 March 2015 you should have benefits in this Arrangement."
          },
          on: {
            "update-parent-value": _vm.updateValue,
            "update-parent-error": _vm.updateError
          }
        }),
        _c("FormInput", {
          attrs: {
            minValue: 0,
            maxValue: 1000000,
            dirtyInput: _vm.dirtyInput,
            inputType: "pounds",
            dataKey: "accruedCARE",
            formLabel: "Annual Pension in the Career Average Arrangement",
            dataValue: _vm.data.benefits.accruedCARE,
            helpText:
              "You should have benefits in this Arrangement unless you’re close to your Normal Pension Age."
          },
          on: {
            "update-parent-value": _vm.updateValue,
            "update-parent-error": _vm.updateError
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "introduction" }, [
      _c("p", [
        _vm._v(
          " It's important you provide the most up to date information you have regarding your current TPS benefits. "
        ),
        _c("span", { staticClass: "highlight" }, [
          _vm._v(
            "You can find these by logging into your TPS My Pension Online account."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }