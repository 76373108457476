var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modeller-assumption-wrapper" }, [
    _c("div", { staticClass: "modeller-assumption" }, [
      _c("h2", [_vm._v("Assumptions")]),
      _c("p", [
        _vm._v(
          ' The figures and other information provided on The Girls’ Day School Trust Pensions Review Website ("the Website") are based on a number of assumptions. For example, future changes in the cost of living, investment performance, annuity pricing and life expectancy. These assumptions are likely to be different from actual experience in the future. Some key assumptions are listed below. These should be read in conjunction with the terms of the Website. '
        )
      ]),
      _c("ul", [
        _c("li", [
          _vm._v("Pension figures are shown in real (“today’s money”) terms.")
        ]),
        _c("li", [
          _vm._v("Increases in the cost of living of 2.5% per annum.")
        ]),
        _c("li", [
          _vm._v(
            " The projected pension values are based upon your current pensionable salary (allowing for your illustrated increases relative to assumed general increases) and assume you remain with the GDST until your retirement (on your current part-time basis if you have specified this). Illustrations relating to either staying in the TPS or opting to move to the GDST Flexible Pension Plan allow for the proposed pension increase being proposed under each option. "
          )
        ]),
        _vm._m(0),
        _c("li", [
          _vm._v(
            " Illustrations involving receiving a pension early (or late) and converting pension into cash are based on the current factors. However these are not guaranteed are reviewed from time to time. "
          )
        ]),
        _c("li", [
          _vm._v(
            " The illustrative annuity pension is based on the current factors and guidance for such illustrations. We have illustrated an annuity that will increase in line with the cost of living and pays a surviving spouse 37.5% of the annuity income for the remainder of their life. Actual annuity rates could differ. "
          )
        ]),
        _c("li", [
          _vm._v(
            " Your illustrative DC and drawdown income assumes your fund is fully invested with an annual growth rate of 4.5% (for Medium assumed investment returns), 2.5% (for Low) or 6.5% (for High). These assumptions are for illustrative purposes only and are not guaranteed. It’s important to remember that your DC and drawdown funds can fall and rise in value (rather than grow by a steady percentage each year as shown in this illustration). Actual returns could also be higher or lower than any of the growth rates illustrated within the tool. This illustration assumes that you will increase your drawndown income in line with increases in the cost of living. "
          )
        ]),
        _c("li", [
          _vm._v(
            "Any illustrated tax amounts shown are based on current UK tax rules."
          )
        ]),
        _c("li", [
          _vm._v(
            " The illustrations do now allow for additional deductions in your pension, as a result of Scheme Pays elections, a Pension Share on Divorce, or Lifetime Allowance charges. "
          )
        ]),
        _c("li", [
          _vm._v(
            " The illustrations do not make any allowance for the potential impact of the “McCloud” legal case which is described further in the video guides. "
          )
        ]),
        _c("li", [
          _vm._v(
            " The illustrations do not allow for many “non-standard” TPS benefits including future Faster Accrual, Additional Pension, Additional Voluntary Contributions, and any Buy Out that has been purchased. "
          )
        ]),
        _c("li", [
          _vm._v(
            " The illustration tool makes no allowance for any salary restrictions that can apply to the calculation of retirement benefits. "
          )
        ]),
        _vm.pen80
          ? _c("li", [
              _vm._v(
                " Individuals retiring after Normal Pension Age from deferred status from the 80th Final Salary Arrangement could be entitled to backdated payments, which would be paid as a lump-sum and subject to tax. No allowance for these backdated payments has been made in the illustration tool. "
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v(" The assumed general increases in pay are: "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Remain in the TPS: 3.5% in September 2022 and September 2023, and 2.5% pa thereafter (ie in line with assumed future inflation after 2023). No allowance has been made for any potential future adjustments to salary increases in light of revised TPS costs."
          )
        ]),
        _c("li", [
          _vm._v(
            "Join the GDST Flexible Pension Plan: 7.5% in September 2022, 3.5% in September 2023, and 2.5% pa thereafter (ie in line with assumed future inflation)."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }