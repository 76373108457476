






























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Slider from './../input/slider/Slider.vue';
import DatePickerInput from './../input/DatePickerInput.vue';
import FormInput from './../input/form/FormInput.vue';
import FormInputDate from './../input/form/FormInputDate.vue';
import RadioInput from './../input/radio/RadioInput.vue';
import moment from 'moment';

@Component({
  components: {
    Slider,
    DatePickerInput,
    FormInput,
    RadioInput,
    FormInputDate,
  },
})
export default class InputPanelBenfits extends Vue {
  @Prop() readonly data!: object;
  @Prop() readonly dirtyInput!: boolean;

  private minDate = moment()
    .subtract(24, 'months')
    .toDate();
  private maxDate = moment()
    .subtract(0, 'months')
    .toDate();

  updateValue(key: string, value: any) {
    this.$emit('update-parent-value', key, value);
  }

  updateError(key: string, value: boolean) {
    this.$emit('update-parent-error', key, value);
  }
}
