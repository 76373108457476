






























































import { Component, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import faqList from '@/components/UI/FaqList.vue';
import { faqData } from '../../faqData';

import cloneDeep from 'clone-deep';
import { faq, faqShowLess, faqShowMore } from '../../observables/ui';

@Component({
  components: { faqList, Multiselect },
})
export default class FaqsFiltered extends Vue {
  activeCategoryObject = null;
  faqData = faqData;
  catSearch = '';

  hack = 0;

  updateCatSearch(e: any) {
    const newVal = e.target.value;
    if (newVal === '') {
      this.hack += 1;
    }
    this.catSearch = newVal;
  }

  get showMore() {
    return faq.more;
  }

  get isShowMoreBtn() {
    return this.catSearch !== '' ? false : true;
  }

  handleShowMore() {
    if (this.showMore) {
      faqShowLess();
    } else {
      faqShowMore();
    }

    (this.$refs.faqContainer as HTMLElement).scrollIntoView();
  }
  get filterResults() {
    let faqArray = [];

    if (this.catSearch === '') {
      faqArray = [...this.faqData];
    }

    if (this.catSearch !== '') {
      const arrayOfFaqIndex = [];
      faqArray = cloneDeep(this.faqData);
      faqArray.forEach((data) => {
        const filteredArray = data.faqs.filter((faqItem) => {
          faqItem.openOverride = false;
          let match = false;
          const answerCheck = faqItem.answer.toLowerCase().includes(this.catSearch.toLowerCase());
          const questionCheck = faqItem.question
            .toLowerCase()
            .includes(this.catSearch.toLowerCase());
          if (answerCheck && questionCheck) {
            faqItem.foundIn = 'question and answer';
            faqItem.openOverride = true;
            match = true;
          } else if (questionCheck) {
            faqItem.foundIn = 'question';
            match = true;
          } else if (answerCheck) {
            faqItem.foundIn = 'answer';
            faqItem.openOverride = true;
            match = true;
          }
          return match;
        });

        data.faqs = filteredArray;

        if (data.faqs.length === 0) {
          const faqIndex = faqArray.findIndex((faq) => faq.category === data.category);
          arrayOfFaqIndex.push(faqIndex);
        }

        arrayOfFaqIndex.forEach((faqIndex) => {
          faqArray.splice(faqIndex);
        });
      });
    }

    return faqArray;
  }
}
