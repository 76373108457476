


















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { Validations } from 'vuelidate-property-decorators';
import { required, minLength, email } from 'vuelidate/lib/validators';

@Component({
  components: {
    Multiselect,
  },
})
export default class FeedbackForm extends Vue {
  public isSubmiting = false;
  @Prop() questionValue: string;
  @Prop() path: string;

  public formData: any = {
    firstName: '',
    lastName: '',
    email: '',
    school: '',
    subject: '',
    feedback: '',
  };

  @Validations()
  validations = {
    formData: {
      firstName: { required },
      lastName: { required },
      email: { required, minLength: minLength(3), email },
      school: { required },

      feedback: { required, minLength: minLength(10) },
    },
  };

  get setSubjectInput() {
    const subjectFieldCheck =
      this.formData.subject === 'Something else?' || this.formData.subject === '';
    return subjectFieldCheck ? (this.formData.subject = '') : this.formData.subject;
  }

  get setFormText() {
    return this.path === 'question' ? 'Question' : 'Feedback';
  }

  submitFeedback() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isSubmiting = true;
      this.formData;

      setTimeout(() => {
        this.isSubmiting = false;
      }, 5000);
    }
  }
  created() {
    this.formData.subject = this.questionValue;
  }

  public schoolList = [
    `Bath`,
    `Blackheath High School`,
    `Brighton and Hove High School`,
    `Bromley High School`,
    `Croydon High School`,
    `Howell's School, Llandaff`,
    `Newcastle High School for Girls`,
    `Northampton High School`,
    `Northwood College for Girls`,
    `Kensington Preparatory School`,
    `Norwich High School for Girls`,
    `Nottingham High School for Girls`,
    `Notting Hill & Ealing High School`,
    `Oxford High School`,
    `Portsmouth High School`,
    `Putney High School`,
    `Royal High School`,
    `Sheffield High School`,
    `Shrewsbury High School`,
    `South Hampstead High School`,
    `Streatham & Clapham High School`,
    `Sutton High School`,
    `Sydenham High School`,
    `Wimbledon High School`,
  ];
}
