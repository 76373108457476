






















































import { Component, Vue, Prop } from 'vue-property-decorator';
import vSlider from 'vue-slider-component';
import '@/assets/scss/slider/customSlider.scss';
import { required, between, decimal } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';

@Component({
  components: {
    vSlider,
  },
})
export default class Slider extends Vue {
  @Prop() readonly inputName!: string;
  @Prop() readonly dataValue!: number;
  @Prop() readonly dataKey!: string;
  @Prop() readonly unit!: string;
  @Prop() readonly tooltipData!: string;
  @Prop() readonly minValue!: number;
  @Prop() readonly maxValue!: number;
  @Prop() readonly step!: number;
  @Prop() readonly defaultValue!: number;

  inputValue = null;
  hover = false;
  isErrors = { between: false, required: false, decimal: false };

  @Validations()
  validations = {
    inputValue: {
      required,
      decimal,
      between: between(this.minValue, this.maxValue),
    },
  };

  created() {
    this.inputValue = this.dataValue;
  }

  updateValueFromInput(value: string) {
    this.$v.inputValue.$touch();
    this.inputValue = value;
    if (!this.$v.inputValue.required) {
      return (this.isErrors.required = !this.$v.inputValue.required);
    }
    if (!this.$v.inputValue.between || !this.$v.inputValue.decimal) {
      this.isErrors.required = false;
      this.isErrors.between = !this.$v.inputValue.between;
      this.isErrors.decimal = !this.$v.inputValue.decimal;
      // this.inputValue = previousInputValue;
      this.$emit('update-parent-value', this.dataKey, this.inputValue);
      return;
    }
    // set all errors object as false when vuelidate passes
    Object.keys(this.isErrors).forEach((key) => (this.isErrors[key] = false));
    this.$emit('update-parent-value', this.dataKey, this.inputValue);
  }

  updateValueFromSlider() {
    //reset errors
    Object.keys(this.isErrors).forEach((key) => (this.isErrors[key] = false));
    this.$v.inputValue.$touch();
    this.$emit('update-parent-value', this.dataKey, this.inputValue);
  }
}
