
















import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class IntroVideoHolder extends Vue {
  @Prop() title!: string;

  private runClick() {
    this.$emit('play');
  }
}
