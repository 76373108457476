




























import { Component, Vue } from 'vue-property-decorator';
import UserSelection from '@/components/UI/UserSelection.vue';
import QuestionList from '@/components/UI/QuestionList.vue';
import FeedbackForm from '@/components/input/form/FeedbackForm.vue';

@Component({
  components: {
    UserSelection,
    QuestionList,
    FeedbackForm,
  },
})
export default class FeedbackOrQuestion extends Vue {
  public currentView = 'questionOrFeedback';
  selection = {
    path: '',
    school: '',
    question: '',
  };

  showFeedback(isSomethingElse) {
    if (this.selection.path === 'question' && isSomethingElse) {
      this.currentView = 'feedbackForm';
    }
  }

  showQuestionListOrFeedback(selection: string) {
    if (selection === 'question') {
      this.currentView = 'questionList';
    }
    if (selection === 'feedback') {
      this.currentView = 'feedbackForm';
    }
    this.selection.path = selection;
    return;
  }

  setQuestionValue(question: string) {
    this.selection.question = question;
  }
}
